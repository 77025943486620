const initialInsurance = {
    insurancePermissions:{},
    insuranceLists: [],
    insuranceStatus: [],
    changedInsuranceStatus : [],
    loadingInsurance: false,
    errorsInsurance: {}
}

const  insuranceReducer = (insurance = initialInsurance, action) => {
    switch (action.type) {
        case "GET_INSURANCELIST_PENDING":
        case "ADD_INSURANCE_PENDING":
        case "UPDATE_INSURANCE_PENDING":
        case "UPDATE_INSURANCE_STATUS_PENDING":
        case "DELETE_INSURANCE_PENDING":
        case "GET_INSURANCELISTBYID_PENDING":
            return { ...insurance, loadingInsurance: true };

        case "GET_INSURANCELIST_FULFILLED":
            return {
                ...insurance,
                loadingInsurance: false,
                insuranceLists: action.payload,
                insuranceCount: action.count,
                insurancePermissions:action.permissions,
            };

        case "ADD_INSURANCE_FULFILLED":
            return {
                ...insurance,
                loadingInsurance: false,
                changedInsuranceStatus: action.payload,
            };

        case "UPDATE_INSURANCE_FULFILLED":
            return {
                ...insurance,
                loadingInsurance: false,
                changedInsuranceStatus: action.payload,
            };

        case "UPDATE_INSURANCE_STATUS_FULFILLED":
            return {
                ...insurance,
                loadingInsurance: false,
                changedInsuranceStatus: action.payload,
            };

        case "GET_INSURANCELISTBYID_FULFILLED":
            return {
                ...insurance,
                loadingInsurance: false,
                insuranceLists: action.payload
            };

        case "DELETE_INSURANCE_FULFILLED":
            return {
                ...insurance,
                loadingInsurance: false,
                changedInsuranceStatus: action.payload
            };

        case "RESET_DELETE_INSURANCE_STATUS":
            return {
                ...insurance,
                loadingInsurance: false,
                insuranceStatus:{},
                changedInsuranceStatus: [],
                errorsInsurance:{}
            };

        case "GET_INSURANCELIST_REJECTED":
        case "ADD_INSURANCE_REJECTED":
        case "UPDATE_INSURANCE_REJECTED":
        case "UPDATE_INSURANCE_STATUS_REJECTED":
        case "DELETE_INSURANCE_REJECTED":
        case "GET_INSURANCELISTBYID_REJECTED":
            return {
                ...insurance,
                loadingInsurance: false,
                errorsInsurance: action.payload
            };
        default:
            return insurance;
    }
}

export default insuranceReducer;