const initialInsuranceManager = {
    insuranceManagerPermissions:{},
    insuranceManagerLists: [],
    insuranceManagerStatus: [],
    changedInsuranceManagerStatus : [],
    loadingInsuranceManager: false,
    errorsInsuranceManager: {}
}

const  insuranceManagerReducer = (insuranceManager = initialInsuranceManager, action) => {
    switch (action.type) {
        case "GET_INSURANCE_MANAGERLIST_PENDING":
        case "ADD_INSURANCE_MANAGER_PENDING":
        case "UPDATE_INSURANCE_MANAGER_PENDING":
        case "UPDATE_INSURANCE_MANAGER_STATUS_PENDING":
        case "DELETE_INSURANCE_MANAGER_PENDING":
        case "GET_INSURANCE_MANAGERLISTBYID_PENDING":
            return { ...insuranceManager, loadingInsuranceManager: true };

        case "GET_INSURANCE_MANAGERLIST_FULFILLED":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                insuranceManagerLists: action.payload,
                insuranceManagerCount: action.count,
                insuranceManagerPermissions:action.permissions,
            };

        case "ADD_INSURANCE_MANAGER_FULFILLED":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                changedInsuranceManagerStatus: action.payload,
            };

        case "UPDATE_INSURANCE_MANAGER_FULFILLED":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                changedInsuranceManagerStatus: action.payload,
            };

        case "UPDATE_INSURANCE_MANAGER_STATUS_FULFILLED":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                changedInsuranceManagerStatus: action.payload,
            };

        case "GET_INSURANCE_MANAGERLISTBYID_FULFILLED":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                insuranceManagerLists: action.payload
            };

        case "DELETE_INSURANCE_MANAGER_FULFILLED":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                changedInsuranceManagerStatus: action.payload
            };

        case "RESET_DELETE_INSURANCE_MANAGER_STATUS":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                insuranceManagerStatus:{},
                changedInsuranceManagerStatus: [],
                errorsInsuranceManager:{}
            };

        case "GET_INSURANCE_MANAGERLIST_REJECTED":
        case "ADD_INSURANCE_MANAGER_REJECTED":
        case "UPDATE_INSURANCE_MANAGER_REJECTED":
        case "UPDATE_INSURANCE_MANAGER_STATUS_REJECTED":
        case "DELETE_INSURANCE_MANAGER_REJECTED":
        case "GET_INSURANCE_MANAGERLISTBYID_REJECTED":
            return {
                ...insuranceManager,
                loadingInsuranceManager: false,
                errorsInsuranceManager: action.payload
            };
        default:
            return insuranceManager;
    }
}

export default insuranceManagerReducer;