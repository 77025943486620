import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getInsuranceListById, saveInsuranceData, updateInsuranceData } from "../../redux/actions/InsuranceActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddInsurance = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    insuranceReducer: {
      loadingInsurance,
      errorsInsurance,
      insuranceLists,
      changedInsuranceStatus,
    },
    masterReducer: {
      loadingMaster,
      masterStateLists,
      masterCityStateWiseLists,
    },
  } = useSelector((state) => state);

  const [validated, setValidated] = useState(false);
  

  useEffect(() => {
    dispatch(getAllStateList(auth.token));
    setFormData({})
    if(editId) {
      dispatch(getInsuranceListById(auth?.token, editId));
    }
  }, [auth, editId]);

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId){
      dispatch(updateInsuranceData(auth?.token, formData, editId));
    } else {
      dispatch(saveInsuranceData(auth?.token, formData));
    }
  };

  
  useEffect(() => {
    if(editId && insuranceLists && Object.keys(insuranceLists).length > 0) {
      setFormData({
        name: insuranceLists?.name,
        address: insuranceLists?.address,
        email: insuranceLists?.email,
        mobile_number: insuranceLists?.mobile_number,
        landline_number: insuranceLists?.landline_number,
        pincode: insuranceLists?.pincode,
        gstin: insuranceLists?.gstin,
        state_code: insuranceLists?.insurance_cities?.cities_state?.state_code,
        city_code: insuranceLists?.insurance_cities?.city_code,
      });
    }
  }, [insuranceLists]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const selectPincodeHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
    
  };

  const selectBlockSpecialHandler = (e) => {
    const { value, name } = e.target;
    if (/^[a-z0-9]{0,30}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
        'official_mail_id': `${value}@asg.com`,
      }));
    }
  };

  
  useEffect(() => {
    if (changedInsuranceStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedInsuranceStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_INSURANCE_STATUS' });
      
    }
  }, [changedInsuranceStatus, dispatch]);


  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && insuranceLists && Object.keys(insuranceLists).length > 0) ? t("Update Insurance") : t("Add Insurance")}</Modal.Title>
      </Modal.Header>
        <Modal.Body>
            {errorsInsurance?.data?.message && (
              <div className="alert alert-danger">
                {errorsInsurance?.data?.message}
              </div>
            )}
          <Row>
          
            <Col lg={6}>
              <FormInput
                label={t("Insurance Company Name")}
                type="text"
                errors={errorsInsurance?.data}
                value={formData.name}
                name="name"
                required={true}
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                label={t("Address")}
                type="text"
                errors={errorsInsurance?.data}
                value={formData.address}
                name="address"
                required={true}
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
            </Col>
            
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("State")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterStateLists}
                  values={masterStateLists?.filter(state => state.state_code === formData.state_code)}
                  name="state_code"
                  labelField={"state_name"}
                  valueField={"state_code"}
                  searchBy={"state_name"}
                  loading={loadingMaster}
                  onChange={(selectedData) => {
                    selectHandler2(selectedData[0]?.state_code, "state_code")
                    setStateId(selectedData[0]?.state_code);
                  }}
                />
                
                {errorsInsurance?.data?.state_code && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsInsurance?.data?.state_code}</p>
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("City")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterCityStateWiseLists}
                  values={masterCityStateWiseLists?.filter(city => city.city_code === formData.city_code)}
                  name="city_code"
                  labelField={"city_name"}
                  valueField={"city_code"}
                  searchBy={"city_name"}
                  loading={loadingMaster}
                  onChange={(selectedData) => {
                    selectHandler2(selectedData[0]?.city_code, "city_code")
                  }}
                />
                
                {errorsInsurance?.data?.city_code && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsInsurance?.data?.city_code}</p>
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <FormInput
                label={t("Pincode")}
                type="text"
                errors={errorsInsurance?.data}
                value={formData.pincode}
                name="pincode"
                required={true}
                onChange={selectPincodeHandler}
                containerClass={"mb-3"}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                label={t("Email Address")}
                type="text"
                errors={errorsInsurance?.data}
                value={formData.email}
                name="email"
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                label={t("Mobile Number")}
                type="text"
                errors={errorsInsurance?.data}
                value={formData.mobile_number}
                name="mobile_number"
                required={true}
                onChange={selectMobileHandler}
                containerClass={"mb-3"}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                label={t("Landline Number")}
                type="text"
                errors={errorsInsurance?.data}
                value={formData.landline_number}
                name="landline_number"
                onChange={selectBlockSpecialHandler}
                containerClass={"mb-3"}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                label={t("GSTIN Number")}
                type="text"
                errors={errorsInsurance?.data}
                value={formData.gstin}
                name="gstin"
                onChange={selectBlockSpecialHandler}
                containerClass={"mb-3"}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {(editId && insuranceLists && Object.keys(insuranceLists).length > 0) ? t("Update") : t("Add")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      {loadingInsurance && <Preloader />}
    </Modal>
  );
};

export default AddInsurance;
