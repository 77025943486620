const initialIncome = {
    incomePermissions:{},
    incomeLists: [],
    incomeStatus: [],
    changedIncomeStatus : [],
    loadingIncome: false,
    errorsIncome: {}
}

const  incomeReducer = (income = initialIncome, action) => {
    switch (action.type) {
        case "GET_INCOMELIST_PENDING":
        case "ADD_INCOME_PENDING":
        case "UPDATE_INCOME_PENDING":
        case "UPDATE_INCOME_STATUS_PENDING":
        case "DELETE_INCOME_PENDING":
        case "GET_INCOMELISTBYID_PENDING":
            return { ...income, loadingIncome: true };

        case "GET_INCOMELIST_FULFILLED":
            return {
                ...income,
                loadingIncome: false,
                incomeLists: action.payload,
                incomeCount: action.count,
                incomePermissions:action.permissions,
            };

        case "ADD_INCOME_FULFILLED":
            return {
                ...income,
                loadingIncome: false,
                changedIncomeStatus: action.payload,
            };

        case "UPDATE_INCOME_FULFILLED":
            return {
                ...income,
                loadingIncome: false,
                changedIncomeStatus: action.payload,
            };

        case "UPDATE_INCOME_STATUS_FULFILLED":
            return {
                ...income,
                loadingIncome: false,
                changedIncomeStatus: action.payload,
            };

        case "GET_INCOMELISTBYID_FULFILLED":
            return {
                ...income,
                loadingIncome: false,
                incomeLists: action.payload
            };

        case "DELETE_INCOME_FULFILLED":
            return {
                ...income,
                loadingIncome: false,
                changedIncomeStatus: action.payload
            };

        case "RESET_DELETE_INCOME_STATUS":
            return {
                ...income,
                loadingIncome: false,
                incomeStatus:{},
                changedIncomeStatus: [],
                errorsIncome:{}
            };

        case "GET_INCOMELIST_REJECTED":
        case "ADD_INCOME_REJECTED":
        case "UPDATE_INCOME_REJECTED":
        case "UPDATE_INCOME_STATUS_REJECTED":
        case "DELETE_INCOME_REJECTED":
        case "GET_INCOMELISTBYID_REJECTED":
            return {
                ...income,
                loadingIncome: false,
                errorsIncome: action.payload
            };
        default:
            return income;
    }
}

export default incomeReducer;