import apiClient from "../../axios/apiClient";



export const getDesignationList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_DESIGNATIONLIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Designation`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_DESIGNATIONLIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_DESIGNATIONLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveDesignationData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_DESIGNATION_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/Add-Designation`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_DESIGNATION_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_DESIGNATION_REJECTED", payload: error.response });
    }
  };
  

  export const getDesignationListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_DESIGNATIONLISTBYID_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Designation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_DESIGNATIONLISTBYID_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_DESIGNATIONLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateDesignationData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_DESIGNATION_PENDING" });

    const response = await apiClient.post(
      `/api/admin/v1/Update-Designation/${id}`, // Endpoint URL with vehicle ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_DESIGNATION_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_DESIGNATION_REJECTED", payload: error.response });
  }
};


export const updateDesignationStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_DESIGNATION_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await apiClient.post(
      `/api/admin/v1/Update-Designation-Status/${id}`, // Endpoint URL with vehicle ID
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_DESIGNATION_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_DESIGNATION_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteDesignationData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_DESIGNATION_PENDING" });

    const response = await apiClient.delete(
      `/api/admin/v1/Delete-Designation/${id}`, // Endpoint URL with vehicle ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_DESIGNATION_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_DESIGNATION_REJECTED", payload: error.response });
  }
};