const initialZone = {
    zonePermissions:{},
    zoneLists: [],
    zoneStatus: [],
    changedZoneStatus : [],
    loadingZone: false,
    errorsZone: {}
}

const  zoneReducer = (state = initialZone, action) => {
    switch (action.type) {
        case "GET_ZONELIST_PENDING":
        case "ADD_ZONE_PENDING":
        case "UPDATE_ZONE_PENDING":
        case "UPDATE_ZONE_STATUS_PENDING":
        case "DELETE_ZONE_PENDING":
        case "GET_ZONELISTBYID_PENDING":
            return { ...state, loadingZone: true };

        case "GET_ZONELIST_FULFILLED":
            return {
                ...state,
                loadingZone: false,
                zoneLists: action.payload,
                zoneCount: action.zoneCount,
                zonePermissions:action.permissions,
            };

        case "ADD_ZONE_FULFILLED":
            return {
                ...state,
                loadingZone: false,
                changedZoneStatus: action.payload,
            };

        case "UPDATE_ZONE_FULFILLED":
            return {
                ...state,
                loadingZone: false,
                changedZoneStatus: action.payload,
            };

        case "UPDATE_ZONE_STATUS_FULFILLED":
                return {
                    ...state,
                    loadingZone: false,
                    changedZoneStatus: action.payload,
                };

        case "GET_ZONELISTBYID_FULFILLED":
            return {
                ...state,
                loadingZone: false,
                zoneLists: action.payload
            };

        case "DELETE_ZONE_FULFILLED":
            return {
                ...state,
                loadingZone: false,
                changedZoneStatus: action.payload
            };

        case "RESET_DELETE_ZONE_STATUS":
            return {
                ...state,
                loadingZone: false,
                changedZoneStatus: [],
                errorsZone:{}
            };

        case "GET_ZONELIST_REJECTED":
        case "ADD_ZONE_REJECTED":
        case "UPDATE_ZONE_REJECTED":
        case "UPDATE_ZONE_STATUS_REJECTED":
        case "DELETE_ZONE_REJECTED":
        case "GET_ZONELISTBYID_REJECTED":
            return {
                ...state,
                loadingZone: false,
                errorsZone: action.payload
            };

        default:
            return state;
    }
}

export default zoneReducer;