import apiClient from "../../axios/apiClient";

export const getGeneralSettingList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_GENERAL_SETTING_LIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/General-Settings`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_GENERAL_SETTING_LIST_FULFILLED",
        payload: response.data?.data,
        permissions:response.data?.permissions,
        generalSettingCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_GENERAL_SETTING_LIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveGeneralSettingData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_GENERAL_SETTING_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/General-Settings`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_GENERAL_SETTING_FULFILLED", payload:response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_GENERAL_SETTING_REJECTED", payload:error.response });
    }
  };



