const initialInjuryType = {
    injuryTypePermissions:{},
    injuryTypeLists: [],
    injuryTypeStatus: [],
    changedInjuryTypeStatus : [],
    loadingInjuryType: false,
    errorsInjuryType: {}
}

const  injuryTypeReducer = (injuryType = initialInjuryType, action) => {
    switch (action.type) {
        case "GET_INJURYTYPELIST_PENDING":
        case "ADD_INJURYTYPE_PENDING":
        case "UPDATE_INJURYTYPE_PENDING":
        case "UPDATE_INJURYTYPE_STATUS_PENDING":
        case "DELETE_INJURYTYPE_PENDING":
        case "GET_INJURYTYPELISTBYID_PENDING":
            return { ...injuryType, loadingInjuryType: true };

        case "GET_INJURYTYPELIST_FULFILLED":
            return {
                ...injuryType,
                loadingInjuryType: false,
                injuryTypeLists: action.payload,
                injuryTypeCount: action.count,
                injuryTypePermissions:action.permissions,
            };

        case "ADD_INJURYTYPE_FULFILLED":
            return {
                ...injuryType,
                loadingInjuryType: false,
                changedInjuryTypeStatus: action.payload,
            };

        case "UPDATE_INJURYTYPE_FULFILLED":
            return {
                ...injuryType,
                loadingInjuryType: false,
                changedInjuryTypeStatus: action.payload,
            };

        case "UPDATE_INJURYTYPE_STATUS_FULFILLED":
            return {
                ...injuryType,
                loadingInjuryType: false,
                changedInjuryTypeStatus: action.payload,
            };

        case "GET_INJURYTYPELISTBYID_FULFILLED":
            return {
                ...injuryType,
                loadingInjuryType: false,
                injuryTypeLists: action.payload
            };

        case "DELETE_INJURYTYPE_FULFILLED":
            return {
                ...injuryType,
                loadingInjuryType: false,
                changedInjuryTypeStatus: action.payload
            };

        case "RESET_DELETE_INJURYTYPE_STATUS":
            return {
                ...injuryType,
                loadingInjuryType: false,
                injuryTypeStatus:{},
                changedInjuryTypeStatus: [],
                errorsInjuryType:{}
            };

        case "GET_INJURYTYPELIST_REJECTED":
        case "ADD_INJURYTYPE_REJECTED":
        case "UPDATE_INJURYTYPE_REJECTED":
        case "UPDATE_INJURYTYPE_STATUS_REJECTED":
        case "DELETE_INJURYTYPE_REJECTED":
        case "GET_INJURYTYPELISTBYID_REJECTED":
            return {
                ...injuryType,
                loadingInjuryType: false,
                errorsInjuryType: action.payload
            };
        default:
            return injuryType;
    }
}

export default injuryTypeReducer;