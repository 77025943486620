import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { getMenuItems } from "../helpers/Menu";
import { LayoutTypes } from "../constants/layout";
import AppMenu from "./Menu";
import profileImg from "../assets/images/users/user-1.jpg";
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoDark2 from "../assets/images/logo-dark-2.png";
import logoLight from "../assets/images/logo-light.png";
import logoLight2 from "../assets/images/logo-light-2.png";

const UserBox = () => {
  const ProfileMenus = [
    // {
    //   label: "My Account",
    //   icon: "fe-user",
    //   redirectTo: "#",
    // },
    {
      label: "Settings",
      icon: "fe-settings",
      redirectTo: "General-Settings",
    },
    {
      label: "Lock Screen",
      icon: "fe-lock",
      redirectTo: "/auth/lock-screen",
    },
    {
      label: "Logout",
      icon: "fe-log-out",
      redirectTo: "/auth/logout",
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="user-box text-center">
      <img
        src={profileImg}
        alt=""
        title="Mat Helme"
        className="rounded-circle avatar-md"
      />
      <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
        <Dropdown.Toggle
          id="dropdown-notification"
          as="a"
          onClick={toggleDropdown}
          className="cursor-pointer text-dark h5 mt-2 mb-1 d-block"
        >
          Geneva Kennedy
        </Dropdown.Toggle>
        <Dropdown.Menu className="user-pro-dropdown">
          <div onClick={toggleDropdown}>
            {(ProfileMenus || []).map((item, index) => (
              <Link
                to={item.redirectTo}
                className="dropdown-item notify-item"
                key={index + "-profile-menu"}
              >
                <i className={`${item.icon} me-1`}></i>
                <span>{item.label}</span>
              </Link>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <p className="text-muted">Admin Head</p>
    </div>
  );
};

// const SideBarContent = () => {
//   return (
//     <>
//       <UserBox />
//       <AppMenu menuItems={getMenuItems()} />
//       <div className="clearfix" />
//     </>
//   );
// };

const SideBarContent = () => {
  const { AuthReducer: { auth } }= useSelector((state) => state);
  return (
    <>
      <UserBox />
      <AppMenu menuItems={auth?.menuData} />
      <div className="clearfix" />
    </>
  );
};

const LeftSidebar = ({ isCondensed, hideLogo }) => {
  const menuNodeRef = useRef(null);

  const { layoutType } = useSelector((state) => ({
    layoutType: state.layoutReducer.layoutType,
    leftSideBarType: state.layoutReducer.leftSideBarType,
  }));

  const handleOtherClick = (e) => {
    if (
      menuNodeRef &&
      menuNodeRef.current &&
      !menuNodeRef.current.contains(e.target)
    ) {
      document.body.classList.remove("sidebar-enable");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOtherClick, false);
    return () => {
      document.removeEventListener("mousedown", handleOtherClick, false);
    };
  }, []);
  
  return (
    <React.Fragment>
      <div className="app-menu" ref={menuNodeRef}>
        {!hideLogo && (
          <div className="logo-box">
            <Link to="/" className="logo logo-dark text-center">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="70" />
              </span>
              <span className="logo-lg">
                <img
                  src={
                    layoutType === LayoutTypes.LAYOUT_TWO_COLUMN
                      ? logoDark2
                      : logoDark
                  }
                  alt=""
                  height="70"
                />
              </span>
            </Link>
            <Link to="/" className="logo logo-light text-center">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="70" />
              </span>
              <span className="logo-lg">
                <img
                  src={
                    layoutType === LayoutTypes.LAYOUT_TWO_COLUMN
                      ? logoLight2
                      : logoLight
                  }
                  alt=""
                  height="70"
                />
              </span>
            </Link>
          </div>
        )}

        {!isCondensed ? (
          <SimpleBar className="scrollbar show h-100" scrollbarMaxSize={320}>
            <SideBarContent />
          </SimpleBar>
        ) : (
          <SideBarContent />
        )}
      </div>
    </React.Fragment>
  );
};

LeftSidebar.defaultProps = {
  isCondensed: false,
  hideLogo: true,
};

export default LeftSidebar;
