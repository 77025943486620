const MENU_ITEMS = [
    {
      key: "dashboards",
      label: "Dashboards",
      isTitle: false,
      icon: "airplay",
      url: "/",
    },
    {
      key: "master",
      label: "Master",
      isTitle: false,
      icon: "airplay",
      children: [
        {
          key: "master-users",
          label: "Users",
          url: "/Users",
          parentKey: "master",
        },
        {
          key: "master-state",
          label: "State",
          url: "/State",
          parentKey: "master",
        },
        {
          key: "master-city",
          label: "City",
          url: "/City",
          parentKey: "master",
        },
        {
          key: "master-geofence",
          label: "Geofence",
          url: "/Geofence",
          parentKey: "master",
        },
        
      ],
    },
    {
      key: "hospital-master",
      label: "Hospital Master",
      isTitle: false,
      icon: "list",
      children: [
        {
          key: "hospital",
          label: "Hospital",
          url: "/Hospital",
          parentKey: "hospital-master",
        },
      ],
    },
    {
      key: "insurance-master",
      label: "Insurance Master",
      isTitle: false,
      icon: "list",
      children: [
        {
          key: "designation",
          label: "Designation",
          url: "/Designation",
          parentKey: "insurance-master",
        },
        {
          key: "type-of-claim",
          label: "Type Of Claim",
          url: "/Type-Of-Claim",
          parentKey: "insurance-master",
        },
        {
          key: "insurance-company",
          label: "Insurance Company",
          url: "/Insurance-Company",
          parentKey: "insurance-master",
        },
        {
          key: "insurance-manager",
          label: "Insurance Manager",
          url: "/Insurance-Manager",
          parentKey: "insurance-master",
        },
      ],
    },
  ];
  
  export { MENU_ITEMS };
  