import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLeaveListById, saveLeaveData, updateLeaveData } from "../../redux/actions/LeaveActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList, getMasterUsertypeUserWiseList, getMasterUserUserTypeWiseList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";


const AddLeave = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const [userTypeId, setUserTypeId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    userPermissionReducer: {
      loadingUserPermission,
      userPermissionByUserIdLists,
      errorsUserPermission,
      userPermissionLists,
      changedUserPermissionStatus,
    },
    masterReducer: {
      loadingMaster,
      masterUsertypeLists,
      masterUserLists,
      errorsMaster,
    },
    leaveReducer: {
      leavePermissions,
      leaveLists,
      loadingLeave,
      errorsLeave,
      leaveCount,
      changedLeaveStatus,
    },
  } = useSelector((state) => state);

  const leaveTypeMaster = [
    {
      id: "Half Day",
      name: 'Half Day'
    },
    {
      id: "Full Day",
      name: 'Full Day'
    },
    {
      id: "W.F.H.",
      name: 'Work from home'
    }
  ]

  useEffect(() => {
    dispatch(getMasterUsertypeUserWiseList(auth?.token));
  }, [auth]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
    }
  }, [errors, dispatch]);

  useEffect(() =>{
    if(userTypeId)
    {
      dispatch(getMasterUserUserTypeWiseList(auth?.token, userTypeId));
    }
  },[userTypeId])



  useEffect(() => {
    setFormData({})
    if(editId) {
      dispatch(getLeaveListById(auth?.token, editId));
    }
  }, [auth, editId]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId)
    {
      dispatch(updateLeaveData(auth?.token, formData,editId));
    }
    else
    {
      setFormData({
        leave_start: startDate,
        leave_end: endDate,
      });
      dispatch(saveLeaveData(auth?.token, formData));
    }
  };

  useEffect(()=>{
    if(editId && leaveLists && Object.keys(leaveLists).length > 0) {
      setFormData({
        leave_type: leaveLists?.leave_type,
        leave_start: leaveLists?.leave_start,
        leave_end: leaveLists?.leave_end,
        reason: leaveLists?.reason,
      });
    }
  },[leaveLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);

  useEffect(() => {
    if (errorsMaster && Object.keys(errorsMaster).length > 0) {
      if(errorsMaster?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        onHide();
      }
    }
  }, [errorsMaster]);

  useEffect(() => {
    if (changedLeaveStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedLeaveStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
    }
  }, [changedLeaveStatus, dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    // console.log(selectedData);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0]?.id
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };


  return (
    <Modal show={show} onHide={onHide} size="md" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && leaveLists && Object.keys(leaveLists).length > 0) ? t("Update Call Logs") : t("Add Call Logs")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {errorsLeave?.data?.message && (
              <div className="alert alert-danger">
                {errorsLeave?.data?.message}
              </div>
            )}
          <Row>
            {/* <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("User Type")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterUsertypeLists}
                  values={masterUsertypeLists.filter((state) => state.id === formData.user_type_id)}
                  name="user_type_id"
                  labelField={"name"}
                  valueField={"id"}
                  loading={loadingMaster}
                  searchBy="name"
                  onChange={(selectedData) => {
                    selectHandler(selectedData, "user_type_id");
                    setUserTypeId(selectedData[0]?.id);
                  }}
                  styles={customSelectStyles}
                />
                
                {errorsLeave?.data?.user_type_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsLeave?.data?.user_type_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col> */}

            {/* <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("User")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterUserLists.map(user => ({
                    ...user,
                    label: `${user.name} || ${user?.citiesAdminUsers?.city_name}`,
                  }))}
                  values={masterUserLists.filter((state) => state.id === formData.request_id)}
                  name="request_id"
                  labelField={"label"}
                  valueField={"id"}
                  loading={loadingMaster}
                  searchBy="label"
                  onChange={(selectedData) => {
                    selectHandler(selectedData, "request_id");
                    setUserId(selectedData[0]?.id);
                  }}
                  styles={customSelectStyles}
                />
                
                {errorsLeave?.data?.request_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsLeave?.data?.request_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col> */}
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Leave Type")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={leaveTypeMaster}
                  values={leaveTypeMaster.filter((state) => state.id === formData.leave_type)}
                  name="leave_type"
                  labelField={"name"}
                  valueField={"id"}
                  loading={loadingMaster}
                  searchBy="name"
                  onChange={(selectedData) => {
                    selectHandler(selectedData, "leave_type");
                    setUserTypeId(selectedData[0]?.id);
                  }}
                  styles={customSelectStyles}
                />
                
                {errorsLeave?.data?.leave_type && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsLeave?.data?.leave_type}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Date")}</Form.Label>
                <DatePicker
                  selectsRange={true}
                  dateFormat="yyyy-MM-dd"
                  startDate={startDate}
                  endDate={endDate}
                  minDate={addDays(new Date(),1)}
                  todayButton="Today"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control"
                  onChange={(update) => {
                    setDateRange(update);
                    console.log('update',update)
                    setFormData(prevFormData => ({
                      ...prevFormData,
                      leave_start: update[0],
                      leave_end: update[1] || update[0]
                    }));
                  }}
                  withPortal
                />
                {errorsLeave?.data?.startDate && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsLeave?.data?.startDate}</p>
                  </div>
                )}
                {errorsLeave?.data?.endDate && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsLeave?.data?.endDate}</p>
                  </div>
                )}
              </Form.Group>

              <Col lg={12}>
              <FormInput
                label={t("Leave Reason")}
                type="text"
                errors={errorsLeave?.data}
                value={formData.reason}
                name="reason"
                required={true}
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
            </Col>
              
            </Col>

          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {(editId && leaveLists && Object.keys(leaveLists).length > 0) ? t("Update") : t("Add")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      {loadingLeave && <Preloader />}
    </Modal>
  );
};

export default AddLeave;
