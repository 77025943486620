const initialCallLogs = {
    callLogsPermissions:{},
    callLogsLists: [],
    callLogsStatus: [],
    changedCallLogsStatus : [],
    loadingCallLogs: false,
    errorsCallLogs: {}
}

const  callLogsReducer = (state = initialCallLogs, action) => {
    switch (action.type) {
        case "GET_CALL_LOGSLIST_PENDING":
        case "ADD_CALL_LOGS_PENDING":
        case "UPDATE_CALL_LOGS_PENDING":
        case "UPDATE_CALL_LOGS_STATUS_PENDING":
        case "DELETE_CALL_LOGS_PENDING":
        case "GET_CALL_LOGSLISTBYID_PENDING":
            return { ...state, loadingCallLogs: true };

        case "GET_CALL_LOGSLIST_FULFILLED":
            return {
                ...state,
                loadingCallLogs: false,
                callLogsLists: action.payload,
                callLogsCount: action.count,
                callLogsPermissions:action.permissions,
            };

        case "ADD_CALL_LOGS_FULFILLED":
            return {
                ...state,
                loadingCallLogs: false,
                changedCallLogsStatus: action.payload,
            };

        case "UPDATE_CALL_LOGS_FULFILLED":
            return {
                ...state,
                loadingCallLogs: false,
                changedCallLogsStatus: action.payload,
            };

        case "UPDATE_CALL_LOGS_STATUS_FULFILLED":
                return {
                    ...state,
                    loadingCallLogs: false,
                    changedCallLogsStatus: action.payload,
                };

        case "GET_CALL_LOGSLISTBYID_FULFILLED":
            return {
                ...state,
                loadingCallLogs: false,
                callLogsLists: action.payload
            };

        case "DELETE_CALL_LOGS_FULFILLED":
            return {
                ...state,
                loadingCallLogs: false,
                changedCallLogsStatus: action.payload
            };

        case "RESET_DELETE_CALL_LOGS_STATUS":
            return {
                ...state,
                loadingCallLogs: false,
                changedCallLogsStatus: [],
                errorsCallLogs:{}
            };

        case "GET_CALL_LOGSLIST_REJECTED":
        case "ADD_CALL_LOGS_REJECTED":
        case "UPDATE_CALL_LOGS_REJECTED":
        case "UPDATE_CALL_LOGS_STATUS_REJECTED":
        case "DELETE_CALL_LOGS_REJECTED":
        case "GET_CALL_LOGSLISTBYID_REJECTED":
            return {
                ...state,
                loadingCallLogs: false,
                errorsCallLogs: action.payload
            };

        default:
            return state;
    }
}

export default callLogsReducer;