import apiClient from "../../axios/apiClient";


export const getInsuranceList =
  (token, limit = 10, page = 1, search = "", sort = "desc",filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_INSURANCELIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Insurance`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            filters:filters
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_INSURANCELIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_INSURANCELIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveInsuranceData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_INSURANCE_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/Add-Insurance`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_INSURANCE_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_INSURANCE_REJECTED", payload: error.response });
    }
  };
  

  export const getInsuranceListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_INSURANCELISTBYID_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Insurance/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_INSURANCELISTBYID_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_INSURANCELISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateInsuranceData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_INSURANCE_PENDING" });

    const response = await apiClient.post(
      `/api/admin/v1/Update-Insurance/${id}`, // Endpoint URL with vehicle ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_INSURANCE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_INSURANCE_REJECTED", payload: error.response });
  }
};


export const updateInsuranceStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_INSURANCE_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await apiClient.post(
      `/api/admin/v1/Update-Insurance-Status/${id}`, // Endpoint URL with vehicle ID
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_INSURANCE_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_INSURANCE_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteInsuranceData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_INSURANCE_PENDING" });

    const response = await apiClient.delete(
      `/api/admin/v1/Delete-Insurance/${id}`, // Endpoint URL with vehicle ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_INSURANCE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_INSURANCE_REJECTED", payload: error.response });
  }
};