const initialState = {
    geofencePermissions:{},
    geofenceLists: [],
    geofenceStatus: [],
    changedGeofenceStatus : [],
    loadingGeofence: false,
    errorsGeofence: {}
}

const  geofenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_GEOFENCELIST_PENDING":
        case "ADD_GEOFENCE_PENDING":
        case "UPDATE_GEOFENCE_PENDING":
        case "UPDATE_GEOFENCE_STATUS_PENDING":
        case "DELETE_GEOFENCE_PENDING":
        case "GET_GEOFENCELISTBYID_PENDING":
            return { ...state, loadingGeofence: true };

        case "GET_GEOFENCELIST_FULFILLED":
            return {
                ...state,
                loadingGeofence: false,
                geofenceLists: action.payload,
                geofenceCount: action.count,
                geofencePermissions:action.permissions,
            };

        case "ADD_GEOFENCE_FULFILLED":
            return {
                ...state,
                loadingGeofence: false,
                changedGeofenceStatus: action.payload,
            };

        case "UPDATE_GEOFENCE_FULFILLED":
            return {
                ...state,
                loadingGeofence: false,
                changedGeofenceStatus: action.payload,
            };

        case "UPDATE_GEOFENCE_STATUS_FULFILLED":
            return {
                ...state,
                loadingGeofence: false,
                changedGeofenceStatus: action.payload,
            };

        case "GET_GEOFENCELISTBYID_FULFILLED":
            return {
                ...state,
                loadingGeofence: false,
                geofenceLists: action.payload
            };

        case "DELETE_GEOFENCE_FULFILLED":
            return {
                ...state,
                loadingGeofence: false,
                changedGeofenceStatus: action.payload
            };

        case "RESET_DELETE_GEOFENCES_STATUS":
            return {
                ...state,
                loadingGeofence: false,
                changedGeofenceStatus: [],
                errorsGeofence:{}
            };

        case "GET_GEOFENCELIST_REJECTED":
        case "ADD_GEOFENCE_REJECTED":
        case "UPDATE_GEOFENCE_REJECTED":
        case "UPDATE_GEOFENCE_STATUS_REJECTED":
        case "DELETE_GEOFENCE_REJECTED":
        case "GET_GEOFENCELISTBYID_REJECTED":
            return {
                ...state,
                loadingGeofence: false,
                errorsGeofence: action.payload
            };

        default:
            return state;
    }
}

export default geofenceReducer;