import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteCityData, getCityList, updateCityStatusData } from "../../redux/actions/CityActions";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import Preloader from "../../Components/Preloader";
import { getAttendanceMonthlyDetailsList } from "../../redux/actions/AttendanceActions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CSVLink } from 'react-csv';
import { getDisplayDateTimeFormat,getDateTimeMysqlFormatYm } from "../../util/generalUtills";


const MonthlyDetailsAttendance = () => {
  const { t } = useTranslation();
  const { id,month } = useParams();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [editCityId, setEditCityId] = useState(null);
  const {
    AuthReducer: { auth,errors,loading },
    attendanceReducers: {
      monthlyAttendancePermissions,
      monthlyAttendanceLists,
      loadingAttendance,
      errorsAttendance,
      monthlyAttendanceCount,
    },
  } = useSelector((city) => city);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(100);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  // const [month, setMonth] = useState(new Date());
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
  
    const handleImageClick = (imageUrl) => {
      setImageUrl(imageUrl);
      setShowModal(true);
    };
  
    const handleClose = () => {
      setShowModal(false);
      setImageUrl("")
    };
  
  
  const fetchCityList = useCallback(() => {
    if (auth?.token) {
      dispatch(getAttendanceMonthlyDetailsList(auth.token, paginate, page, search, sort,id,month));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchCityList, 500, [auth, paginate, page, search, sort]);

  const csvHead = [['SR No.', 'Name', 'Contact Number', 'User Type',
      'City', 'Working Type', 'Login Time', 'Login Address', 'Logout Time', 'Logout Address']
    ]
    const csvData = monthlyAttendanceLists.map((cv, index) => {
      
      return [
        index + 1,
        cv?.name,
        cv?.phone || '',
        cv?.user_types || '',
        cv?.city + "," + cv?.states || '',
        cv?.type || 'Absent',
        cv?.createdAt ? getDisplayDateTimeFormat(cv?.createdAt) : '',
        cv?.login_address || '',
        cv?.logout_time!=null ? getDisplayDateTimeFormat(cv?.logout_time) : '',
        cv?.logout_address || ''
      ];
    });
    const exportData = csvHead.concat(csvData);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])

  useEffect(() => {
    document.title = `Monthly Attendance Details :: ${auth?.generalSettings?.title}`;
  }, []);

  const monthNames = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Monthly Attendance"), path: "/Attendance/Monthly", active: false },
          { label: t("Monthly Attendance Details"), path: "/Attendance/Monthly", active: true },
        ]}
        title={t(`${monthNames[new Date(month).getMonth()]} Month Attendance Details `)}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              
              <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  id="table-to-print"
                  size="sm"
                  striped
                  responsive
                  >
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>User Type</th>
                      <th>City</th>
                      <th>Working Type</th>
                      <th>Login Time</th>
                      <th>Logout Time</th>
                      <th>Login Photo</th>
                      <th>Logout Photo</th>
                    </tr>
                  </thead>
                  <tbody>
                  {monthlyAttendanceLists.length === 0 ? (
                    <tr>
                      <td colSpan="12">No data found</td>
                    </tr>
                  ) : (<>
                    {monthlyAttendanceLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.userIdAttendance?.name}</td>
                          <td>{item?.userIdAttendance?.phone}</td>
                          <td>{item?.userIdAttendance?.adminUserType?.name}</td>
                          <td>{item?.userIdAttendance?.citiesAdminUsers?.city_name}, {item?.userIdAttendance?.citiesAdminUsers?.cities_state?.state_name}</td>
                          <td>{
                            item?.type=="Present" ? 
                              <>
                                <Badge className={"bg-success"}>
                                  {item?.type}
                                </Badge>
                              </>
                              : item?.type=="Leave" 
                              ? 
                              <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-created-at">
                                        {`${item?.leave_remark}`}
                                      </Tooltip>
                                    }
                                  >
                                  <Badge className={"bg-warning"}>
                                  {item?.type}
                                  </Badge>
                                </OverlayTrigger>
                              </>
                              : 
                              <>
                                <Badge className={"bg-danger"}>
                                {item?.type}
                                </Badge>
                              </>
                              }</td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-created-at">
                                  {item?.login_address}
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block" tabIndex="0">
                                {item?.createdAt ? getDisplayDateTimeFormat(item?.createdAt) : ''}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-created-at">
                                  {item?.logout_address}
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block" tabIndex="0">
                              {item?.logout_time!=null ? getDisplayDateTimeFormat(item?.logout_time) : ''}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          {item?.createdAt != null ? (
                            <img 
                              className="avatar-md rounded" 
                              src={`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.login_image}`} 
                              onClick={() => handleImageClick(`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.login_image}`)} 
                              alt="Login Image"
                            />
                          ) : ''}
                          </td>
                          <td>
                          {item?.logout_time != null ? (
                            <img 
                              className="avatar-md rounded" 
                              src={`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.logout_image}`}
                              onClick={() => handleImageClick(`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.logout_image}`)} 
                              alt="Login Image"
                            />
                          ) : ''}
                          </td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleClose} size="md">
        <Modal.Body>
          <img
            src={imageUrl}
            alt="Full size"
            className="img-fluid"
          />
        </Modal.Body>
      </Modal>
      
      {loadingAttendance && (<Preloader/>)}
    </>
  );
};

export default MonthlyDetailsAttendance;
