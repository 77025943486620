import apiClient from "../../axios/apiClient";


export const getMasterSettings = (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_SETTINGS_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Settings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_SETTINGS_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_SETTINGS_REJECTED",
        payload: error.response,
      });
    }
  };


export const getAllStateList =
  (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_STATE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/State`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_STATE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_STATE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getAllCityStateWiseList= (token, stateId) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_CITY_STATE_WISE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/City`,
        {
          params: {
            state_id: stateId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_CITY_STATE_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        console.error('Error Response Headers:', error.response.headers);
      }
      dispatch({
        type: "GET_MASTER_CITY_STATE_WISE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getMasterUsertypeUserWiseList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_USERTYPE_USER_WISE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Usertype`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_USERTYPE_USER_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_USERTYPE_USER_WISE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getMasterUserUserTypeWiseList= (token, user_type_id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_USER_USERTYPE_WISE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/User-Usertype-Wise`,
        {
          params: {
            user_type_id: user_type_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_USER_USERTYPE_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        console.error('Error Response Headers:', error.response.headers);
      }
      dispatch({
        type: "GET_MASTER_USER_USERTYPE_WISE_REJECTED",
        payload: error.response,
      });
    }
  };


  export const getMasterAllUserList= (token, user_type_id) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_MASTER_USER_USERTYPE_WISE_PENDING" });
  
        const response = await apiClient.get(
          `/api/admin/v1/Master/All-User-List`,
          {
            params: {
              user_type_id: user_type_id,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json"
            },
          }
        );
        // console.log('response.data?.data',response.data?.data);
        dispatch({
          type: "GET_MASTER_USER_USERTYPE_WISE_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.error('Error Response Data:', error.response.data);
          console.error('Error Response Status:', error.response.status);
          console.error('Error Response Headers:', error.response.headers);
        }
        dispatch({
          type: "GET_MASTER_USER_USERTYPE_WISE_REJECTED",
          payload: error.response,
        });
      }
    };

export const getMasterInsuranceList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_INSURANCE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Insurance-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_INSURANCE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_INSURANCE_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllInsuranceManagerWiseList= (token, insuranceId) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_INSURANCE_MANAGER_WISE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Insurance-Manager-List`,
        {
          params: {
            insurance_id: insuranceId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_INSURANCE_MANAGER_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        console.error('Error Response Headers:', error.response.headers);
      }
      dispatch({
        type: "GET_MASTER_INSURANCE_MANAGER_WISE_REJECTED",
        payload: error.response,
      });
    }
  };

export const getMasterDesignationList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_DESIGNATION_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Designation-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_DESIGNATION_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_DESIGNATION_REJECTED",
        payload: error.response,
      });
    }
  };

export const getMasterHospitalList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_HOSPITAL_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Hospital-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_HOSPITAL_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_HOSPITAL_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllRelationshipList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_RELATIONSHIP_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Relationship-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_RELATIONSHIP_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_RELATIONSHIP_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllOccupationList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_OCCUPATION_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Occupation-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_OCCUPATION_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_OCCUPATION_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllLocalityList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_LOCALITY_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Locality-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_LOCALITY_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_LOCALITY_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllEducationalList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_EDUCATION_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Education-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_EDUCATION_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_EDUCATION_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllJobList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_JOB_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Job-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_JOB_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_JOB_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllRoomCategoryList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_ROOM_CATEGORY_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Room-Type-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_ROOM_CATEGORY_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_ROOM_CATEGORY_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllIncomeDetailsList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_INCOME_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Income-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_INCOME_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_INCOME_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllInjuryTypeList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_INJURY_TYPE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Injury-Type-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_INJURY_TYPE_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_INJURY_TYPE_REJECTED",
        payload: error.response,
      });
    }
  };

export const getMasterClaimTypeList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_CLAIM_TYPE_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Claim-Type-List`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MASTER_CLAIM_TYPE_FULFILLED",
        payload: response.data?.data,
      });
    }
    catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_CLAIM_TYPE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getAllCheckList =
  (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_CHECKLIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/Checklist`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_CHECKLIST_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_CHECKLIST_REJECTED",
        payload: error.response,
      });
    }
  };

export const getAllCityList =
  (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_ALL_CITY_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Master/All-City`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_ALL_CITY_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_ALL_CITY_REJECTED",
        payload: error.response,
      });
    }
  };



export const getGeofenceList =
(token) =>
async (dispatch) => {
  try {
    dispatch({ type: "GET_MASTER_GEOFENCE_PENDING" });

    const response = await apiClient.get(
      `/api/admin/v1/Master/Geofence-List`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log('response.data?.data',response.data?.data);
    dispatch({
      type: "GET_MASTER_GEOFENCE_FULFILLED",
      payload: response.data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "GET_MASTER_GEOFENCE_REJECTED",
      payload: error.response,
    });
  }
};


export const getMasterTodayAbsenceList =
(token) =>
async (dispatch) => {
  try {
    dispatch({ type: "GET_MASTER_ABSENCE_PENDING" });

    const response = await apiClient.get(
      `/api/admin/v1/Master/Today-Absent-List`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log('response.data?.data',response.data?.data);
    dispatch({
      type: "GET_MASTER_ABSENCE_FULFILLED",
      payload: response.data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "GET_MASTER_ABSENCE_REJECTED",
      payload: error.response,
    });
  }
};