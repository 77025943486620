import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button ,Modal ,Form } from "react-bootstrap";
import PageTitle from "../../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import RoundedPagination from "../../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../../redux/actions/AuthActions";
import useDebounce from "../../../hooks/useDebounce";
import {getDisplayDateTimeFormat} from '../../../util/generalUtills'
import { deleteUserData, getUserList } from "../../../redux/actions/UserActions";
import Preloader from "../../../Components/Preloader";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";

const filterOptions = ["Equal","Contains", "Greater", "Less"];


const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    userReducer: {
      usersPermissions,
      userLists,
      loadingUsers,
      errorsUsers,
      userCount,
      changeUserStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;

  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState({ show: false, key: "", type: "", value: "" });
  
  // useEffect(() => {
  //   dispatch(getVehicleList(auth?.token, paginate, page, search, sort));
  //   console.log('userLists',userLists);
  // }, [auth, paginate, page, search, sort]);

  const fetchVehicleList = useCallback(() => {
    dispatch({ type: 'RESET_DELETE_USER_STATUS' });
    if (auth?.token) {
      dispatch(getUserList(auth.token, paginate, page, search, sort,filters));
    }
  }, [auth?.token, paginate, page, search, sort,filters, dispatch]);

  useDebounce(fetchVehicleList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
    
  },[errors])


  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? userCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUserData(auth?.token,id))
      }
    });
  };


  useEffect(() => {
    //console.log(changeUserStatus);
    if(changeUserStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changeUserStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATUS' });
      dispatch(getUserList(auth?.token, paginate, page, search, sort,filters));
    }
    
  },[changeUserStatus])

  const handleFilterClick = (key) => {
    console.log(key);
  
    if (!filters[key]) {
      // Only add the filter if it doesn't exist
      setFilters((prev) => ({
        ...prev,
        [key]: { type: "", value: "" },
      }));
    }
  
    setModal({ 
      show: true, 
      key, 
      type: filters[key]?.type || "", 
      value: filters[key]?.value || "" 
    });
  };

  const handleModalClose = (key) => {
    setModal({ show: false, key: "", type: "", value: "" });
  };

  const handleRemoveFilter = (key) => {
    setModal({ show: false, key: "", type: "", value: "" });
    setFilters((prev) => ({
      ...prev,
      [modal.key]: { type: "", value: "" },
    }));
    handleModalClose();
  };

  const handleApplyFilter = () => {
    setFilters((prev) => ({
      ...prev,
      [modal.key]: { type: modal.type, value: modal.value },
    }));
    handleModalClose();
  };

  useEffect(() => {
    console.log(filters);
    dispatch(getUserList(auth.token, paginate, page, search, sort,filters));
  },[filters])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("User"), path: "/Users", active: true },
        ]}
        title={t("User")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
            <div className="mb-2">
              <Row>
                <Col lg={3}>
                  <div className="d-flex align-items-center">
                  {/* <input
                    type="search"
                    placeholder="Search..."
                    className="form-control ms-1"
                    autoComplete="off"
                    value={search}
                    onChange={handleSearchChange}
                  /> */}
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="button-list">
                      { (Object.keys(filters).length>0) && (
                        <>
                          <Button variant="warning" onClick={() => setFilters({})}>
                            <i className="mdi mdi-filter-off me-1"></i>Clear All Filter
                          </Button>
                        </>
                      )
                      }
                      {usersPermissions?.add && (
                        <>
                        <Link to="/Add-User">
                          <Button variant="success">
                            <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                          </Button>
                        </Link>
                        </>
                      )}
                      {usersPermissions?.print && (
                        <>
                        <Link to="/Add-User">
                          <Button variant="dark">
                            <i className="mdi mdi-printer me-1"></i>Print
                          </Button>
                        </Link>
                        </>
                      )}
                      {usersPermissions?.excel && (
                        <>
                        <Link to="/Add-User">
                          <Button variant="info">
                            <i className="mdi mdi-file-excel me-1"></i>Excel
                          </Button>
                        </Link>
                        </>
                      )}
                      
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
              <Table className="mb-0 table-bordered"
                size="sm"
                striped
                responsive
                >
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                  <tr>
                    <th>SR No.</th>
                    <th>
                      Name
                      {usersPermissions?.filter && (
                        <>
                        <Button variant="link" onClick={() => handleFilterClick("name")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>
                      )}
                    </th>
                    <th>
                      Contact
                      {usersPermissions?.filter && (
                        <>
                        <Button variant="link" onClick={() => handleFilterClick("phone")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>
                      )}
                    </th>
                    <th>
                      User Type
                      {usersPermissions?.filter && (
                        <>
                        <Button variant="link" onClick={() => handleFilterClick("usertype")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>
                      )}
                    </th>
                    <th>
                      City
                      {usersPermissions?.filter && (
                        <>
                        <Button variant="link" onClick={() => handleFilterClick("city")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>
                      )}
                      
                    </th>
                    <th>
                      Username
                      {usersPermissions?.filter && (
                        <>
                        <Button variant="link" onClick={() => handleFilterClick("username")}><FontAwesomeIcon icon={faFilter} /></Button>
                        </>
                      )}
                      
                    </th>
                    <th>Created By</th>
                    <th>Registered On</th>
                    {(usersPermissions?.update===true || usersPermissions?.delete===true) && (
                      <th>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                {userLists.length === 0 ? (
                  <tr>
                    <td colSpan="9">No data found</td>
                  </tr>
                ) : (<>
                  {userLists?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                        
                        <td>{item?.name}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.adminUserType?.name}</td>
                        <td>{item?.citiesAdminUsers?.city_name}</td>
                        <td>{item?.username}</td>
                        <td>{item?.createdByAdminUsers?.name}</td>
                        <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                        {(usersPermissions?.update===true || usersPermissions?.delete===true) && (
                          <td>
                            {usersPermissions?.update && (
                              <>
                                <Link to={`/Update-User/${item?.id}`} className="action-icon text-info"> 
                                  <i className="mdi mdi-square-edit-outline"></i>
                                </Link>
                              </>
                            )}
                            {usersPermissions?.delete && (
                              <>
                                <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                                  {" "}
                                  <i className="mdi mdi-delete"></i>
                                </Link>
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}</>
                )}
                </tbody>
              </Table>
            </div>
            <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
              
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(userCount / paginate)}</strong> </span>
                <RoundedPagination
                      currentPage={page}
                      totalPages={Math.ceil(userCount / paginate)}
                      onPageChange={(pageNumber) => setPage(pageNumber)}
                    />
              
              
            </div>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingUsers && (<Preloader/>)}

      <Modal 
    show={modal.show} 
    onHide={handleModalClose} 
    size="sm" // Smaller size
    centered // Centers the modal on the screen
    dialogClassName="compact-modal" // Custom class for additional styling
  >
    <Modal.Header closeButton className="p-2">
      <Modal.Title style={{ fontSize: '1rem' }}>Filter {modal.key}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <Form.Group>
          <Form.Label style={{ fontSize: '0.85rem' }}>Filter Type</Form.Label>
          <Form.Select
            value={modal.type}
            onChange={(e) => setModal((prev) => ({ ...prev, type: e.target.value }))}
            size="sm" // Smaller select input
            style={{ fontSize: '0.85rem' }}
          >
            <option value="">Select Type</option>
            {filterOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label style={{ fontSize: '0.85rem' }}>Value</Form.Label>
          <Form.Control
            type="text"
            value={modal.value}
            onChange={(e) => setModal((prev) => ({ ...prev, value: e.target.value }))}
            size="sm" // Smaller input
            style={{ fontSize: '0.85rem' }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="p-1">
        <Button variant="outline-secondary" size="sm" onClick={() => handleRemoveFilter(modal.key)}>
          <FontAwesomeIcon icon={faTimes} /> Reset
        </Button>
        <Button variant="primary" size="sm" onClick={handleApplyFilter}>
          <FontAwesomeIcon icon={faCheck} /> Apply
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default Dashboard;
