const initialRelationship = {
    relationshipPermissions:{},
    relationshipLists: [],
    relationshipStatus: [],
    changedRelationshipStatus : [],
    loadingRelationship: false,
    errorsRelationship: {}
}

const  relationshipReducer = (relationship = initialRelationship, action) => {
    switch (action.type) {
        case "GET_RELATIONSHIPLIST_PENDING":
        case "ADD_RELATIONSHIP_PENDING":
        case "UPDATE_RELATIONSHIP_PENDING":
        case "UPDATE_RELATIONSHIP_STATUS_PENDING":
        case "DELETE_RELATIONSHIP_PENDING":
        case "GET_RELATIONSHIPLISTBYID_PENDING":
            return { ...relationship, loadingRelationship: true };

        case "GET_RELATIONSHIPLIST_FULFILLED":
            return {
                ...relationship,
                loadingRelationship: false,
                relationshipLists: action.payload,
                relationshipCount: action.count,
                relationshipPermissions:action.permissions,
            };

        case "ADD_RELATIONSHIP_FULFILLED":
            return {
                ...relationship,
                loadingRelationship: false,
                changedRelationshipStatus: action.payload,
            };

        case "UPDATE_RELATIONSHIP_FULFILLED":
            return {
                ...relationship,
                loadingRelationship: false,
                changedRelationshipStatus: action.payload,
            };

        case "UPDATE_RELATIONSHIP_STATUS_FULFILLED":
            return {
                ...relationship,
                loadingRelationship: false,
                changedRelationshipStatus: action.payload,
            };

        case "GET_RELATIONSHIPLISTBYID_FULFILLED":
            return {
                ...relationship,
                loadingRelationship: false,
                relationshipLists: action.payload
            };

        case "DELETE_RELATIONSHIP_FULFILLED":
            return {
                ...relationship,
                loadingRelationship: false,
                changedRelationshipStatus: action.payload
            };

        case "RESET_DELETE_RELATIONSHIP_STATUS":
            return {
                ...relationship,
                loadingRelationship: false,
                relationshipStatus:{},
                changedRelationshipStatus: [],
                errorsRelationship:{}
            };

        case "GET_RELATIONSHIPLIST_REJECTED":
        case "ADD_RELATIONSHIP_REJECTED":
        case "UPDATE_RELATIONSHIP_REJECTED":
        case "UPDATE_RELATIONSHIP_STATUS_REJECTED":
        case "DELETE_RELATIONSHIP_REJECTED":
        case "GET_RELATIONSHIPLISTBYID_REJECTED":
            return {
                ...relationship,
                loadingRelationship: false,
                errorsRelationship: action.payload
            };
        default:
            return relationship;
    }
}

export default relationshipReducer;