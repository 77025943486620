const initialDesignation = {
    designationPermissions:{},
    designationLists: [],
    designationStatus: [],
    changedDesignationStatus : [],
    loadingDesignation: false,
    errorsDesignation: {}
}

const  designationReducer = (designation = initialDesignation, action) => {
    switch (action.type) {
        case "GET_DESIGNATIONLIST_PENDING":
        case "ADD_DESIGNATION_PENDING":
        case "UPDATE_DESIGNATION_PENDING":
        case "UPDATE_DESIGNATION_STATUS_PENDING":
        case "DELETE_DESIGNATION_PENDING":
        case "GET_DESIGNATIONLISTBYID_PENDING":
            return { ...designation, loadingDesignation: true };

        case "GET_DESIGNATIONLIST_FULFILLED":
            return {
                ...designation,
                loadingDesignation: false,
                designationLists: action.payload,
                designationCount: action.count,
                designationPermissions:action.permissions,
            };

        case "ADD_DESIGNATION_FULFILLED":
            return {
                ...designation,
                loadingDesignation: false,
                changedDesignationStatus: action.payload,
            };

        case "UPDATE_DESIGNATION_FULFILLED":
            return {
                ...designation,
                loadingDesignation: false,
                changedDesignationStatus: action.payload,
            };

        case "UPDATE_DESIGNATION_STATUS_FULFILLED":
            return {
                ...designation,
                loadingDesignation: false,
                changedDesignationStatus: action.payload,
            };

        case "GET_DESIGNATIONLISTBYID_FULFILLED":
            return {
                ...designation,
                loadingDesignation: false,
                designationLists: action.payload
            };

        case "DELETE_DESIGNATION_FULFILLED":
            return {
                ...designation,
                loadingDesignation: false,
                changedDesignationStatus: action.payload
            };

        case "RESET_DELETE_DESIGNATION_STATUS":
            return {
                ...designation,
                loadingDesignation: false,
                designationStatus:{},
                changedDesignationStatus: [],
                errorsDesignation:{}
            };

        case "GET_DESIGNATIONLIST_REJECTED":
        case "ADD_DESIGNATION_REJECTED":
        case "UPDATE_DESIGNATION_REJECTED":
        case "UPDATE_DESIGNATION_STATUS_REJECTED":
        case "DELETE_DESIGNATION_REJECTED":
        case "GET_DESIGNATIONLISTBYID_REJECTED":
            return {
                ...designation,
                loadingDesignation: false,
                errorsDesignation: action.payload
            };
        default:
            return designation;
    }
}

export default designationReducer;