import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form, Table, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCityListById, saveCityData, updateCityData } from "../../redux/actions/CityActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'
import { getDisplayDateTimeFormat,convertToTimeString ,getUnixDisplayDateTimeFormat } from "../../util/generalUtills";


const CallLogsDetails = ({ show, onHide, tableData }) => {
  const { t } = useTranslation();  

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("Call Details")}</Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
            <Table className="mb-0 table-bordered"
              size="sm"
              striped
              style={{ width: "100%", tableLayout: "fixed" }}>
              <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Status</th>
                  <th>Call Duration</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
              {tableData.length === 0 ? (
                <tr>
                  <td colSpan="6">No data found</td>
                </tr>
              ) : (<>
                {tableData?.map?.((item, index) => {
                  return (
                    <tr key={item.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.name}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{item?.type === "OUTGOING" ? <Badge className={"bg-warning"}>{item?.type}</Badge> : <> {item?.type === "INCOMING" ? <Badge className={"bg-success"}>{item?.type}</Badge> : <Badge className={"bg-danger"}>{item?.type}</Badge>}</>}</td>
                      <td>{convertToTimeString(item?.duration)}</td>
                      <td>{getUnixDisplayDateTimeFormat(item?.timestamp)}</td>
                    </tr>
                  );
                })}</>
              )}
              </tbody>
            </Table>
          </div>
      </Modal.Body>
    </Modal>
  );
};

export default CallLogsDetails;
