import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { checkInServerAction, getAttendanceListById, saveAttendanceData, updateAttendanceData } from "../../redux/actions/AttendanceActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList, getMasterTodayAbsenceList, getMasterUsertypeUserWiseList, getMasterUserUserTypeWiseList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'
import { getCurrentDateym, getCurrentDateYmd } from "../../util/generalUtills";
import DatePicker from "react-datepicker";

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddAttendnace = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [userId, setUserId] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [remark, setRemark] = useState(null);
  const [imageUri, setImageUri] = useState(null);
  const [attendanceDate, setAttendanceDate] = useState(new Date());
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    userPermissionReducer: {
      loadingUserPermission,
      userPermissionByUserIdLists,
      errorsUserPermission,
      userPermissionLists,
      changedUserPermissionStatus,
    },
    masterReducer: {
      loadingMaster,
      masterAbsenceList,
      masterUserLists,
      errorsMaster,
    },
    attendanceReducers: {
      attendanceLists,
      loadingAttendance,
      errorsAttendance,
      attendanceCount,
      changedAttendanceStatus,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getMasterTodayAbsenceList(auth?.token));
  }, [auth]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
    }
  }, [errors, dispatch]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(!lat){
      toast.error('Please enter lattitude.');
      return;
    }
    if(!lng){
      toast.error('Please enter longitude.');
      return;
    }
    if(!remark){
      toast.error('Please enter remark.');
      return;
    }
    const formData = new FormData();
    formData.append('employeeId', userId);
    formData.append('attendance_date', attendanceDate);
    formData.append('lat', lat);
    formData.append('lng', lng);
    formData.append('address', remark);
    formData.append('imei', "");
    formData.append('distance', "0");
    formData.append('subdirectory', `${getCurrentDateym()}/attendance`);
    formData.append('filename', `checkin_${auth?.data?.id}_${getCurrentDateYmd()}.png`);
    formData.append('file', imageUri);
    // if (imageUri.length>0) {
    //   formData.append('file', {
    //     uri: imageUri[0].uri,
    //     name: imageUri[0].fileName,
    //     type: imageUri[0].type,
    //   });
    // }
    console.log('formData',formData);
    dispatch(checkInServerAction(auth?.token,formData))
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);

  useEffect(() => {
    if (errorsMaster && Object.keys(errorsMaster).length > 0) {
      if(errorsMaster?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        onHide();
      }
    }
  }, [errorsMaster]);

  useEffect(() => {
    if (changedAttendanceStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedAttendanceStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
    }
  }, [changedAttendanceStatus, dispatch]);

  useEffect(() => {
    console.log('imageUri',imageUri)
  },[imageUri]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUri(file);
    }
  };
  

  return (
    <Modal show={show} onHide={onHide} size="md" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && attendanceLists && Object.keys(attendanceLists).length > 0) ? t("Update User Attendance") : t("Add User Attendance")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {errorsAttendance?.data?.message && (
              <div className="alert alert-danger">
                {errorsAttendance?.data?.message}
              </div>
            )}
          <Row>
            
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("User")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterAbsenceList.map(user => ({
                    ...user,
                    label: `${user.name} || ${user?.user_types}`,id:`${user?.id}`
                  }))}
                  name="request_id"
                  labelField={"label"}
                  valueField={"id"}
                  loading={loadingMaster}
                  searchBy="label"
                  onChange={(selectedData) => {
                    setUserId(selectedData[0]?.id);
                  }}
                />
                
                {errorsAttendance?.data?.request_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsAttendance?.data?.request_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col lg={6}>
              <FormInput
                label={t("Latitude")}
                type="text"
                errors={errorsAttendance?.data}
                value={lat}
                required={true}
                onChange={(e) => {
                  setLat(e.target.value);
                }}
                containerClass={"mb-3"}
              />
            </Col>

            <Col lg={6}>
              <FormInput
                label={t("Longitude")}
                type="text"
                errors={errorsAttendance?.data}
                value={lng}
                required={true}
                onChange={(e) => {
                  setLng(e.target.value);
                }}
                containerClass={"mb-3"}
              />
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Attendnace Date")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <DatePicker
                  selected={attendanceDate}
                  onChange={(value) => setAttendanceDate(value)}
                  dateFormat="yyyy-MM-dd" // Display the date in yyyy-MM-dd format
                  maxDate={(() => {
                    const date = new Date();
                    //date.setFullYear(date.getFullYear() - 0);  // Subtract 18 years
                    return date;
                  })()}
                  className="form-control"
                  withPortal
                  showYearDropdown
                  scrollableYearDropdown
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <FormInput
                label={t("Remark")}
                type="text"
                errors={errorsAttendance?.data}
                value={remark}
                required={true}
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
                containerClass={"mb-3"}
              />
            </Col>

            <Col lg={12}>
              <FormInput
                label={t("Attendance Photo")}
                type="file"
                name="banner_image"
                onChange={handleImageChange}
                containerClass={"mb-3"}
              />
            </Col>

          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {(editId && attendanceLists && Object.keys(attendanceLists).length > 0) ? t("Update") : t("Add")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      {loadingAttendance && <Preloader />}
    </Modal>
  );
};

export default AddAttendnace;
