const initialAttendance = {
    monthlyAttendancePermissions:{},
    monthlyAttendanceLists: [],
    monthlyAttendanceCount: [],
    dailyAttendancePermissions:{},
    dailyAttendanceLists: [],
    dailyAttendanceCount: [],
    changedAttendanceStatus: [],
    loadingAttendance: false,
    errorsAttendance: {}
}

const  attendanceReducers = (state = initialAttendance, action) => {
    switch (action.type) {
        case "GET_ATTENDANCE_MONTHLY_PENDING":
        case "GET_ATTENDANCE_DAILY_PENDING":
        case "ATTENDANCE_CHECK_IN_ACTION_PENDING":
            return { ...state, loadingAttendance: true };

        case "GET_ATTENDANCE_MONTHLY_FULFILLED":
            return {
                ...state,
                loadingAttendance: false,
                monthlyAttendanceLists: action.payload,
                monthlyAttendanceCount: action.count,
                monthlyAttendancePermissions:action.permissions,
            };

        case "GET_ATTENDANCE_DAILY_FULFILLED":
            return {
                ...state,
                loadingAttendance: false,
                dailyAttendanceLists: action.payload,
                dailyAttendanceCount: action.count,
                dailyAttendancePermissions:action.permissions,
            };

        case "ATTENDANCE_CHECK_IN_ACTION_FULFILLED":
            return {
                ...state,
                loadingAttendance: false,
                changedAttendanceStatus: action.payload,
            };

        case "GET_ATTENDANCE_RESET":
            return {
                ...state,
                loadingAttendance: false,
                dailyAttendanceLists: [],
                dailyAttendanceCount: [],
                monthlyAttendanceLists: [],
                monthlyAttendanceCount: [],
            };

        case "GET_ATTENDANCE_MONTHLY_REJECTED":
        case "GET_ATTENDANCE_DAILY_REJECTED":
        case "ATTENDANCE_CHECK_IN_ACTION_REJECTED":
            return {
                ...state,
                loadingAttendance: false,
                errorsAttendance: action.payload
            };

        default:
            return state;
    }
}

export default attendanceReducers;