import apiClient from "../../axios/apiClient";

export const getCaseDetailsList =
  ( url ,token, limit = 10, page = 1, search = "", sort = "desc",filters) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_CASE_DETAILS_LIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/${url}`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            filters:filters,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_CASE_DETAILS_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    }
    catch (error) {
      dispatch({
        type: "GET_CASE_DETAILS_LIST_REJECTED",
        payload: error.response,
      });
    }
  };

  export const saveCaseInitiatedData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_CASE_INITIATED_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/Initiated`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_CASE_INITIATED_FULFILLED", payload: response.data });
    }
    catch (error) {
      dispatch({ type: "ADD_CASE_INITIATED_REJECTED", payload: error.response });
    }
  };

  export const saveCaseGeneratedData = (token, data, id) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_CASE_GENERATED_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/Generated/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_CASE_GENERATED_FULFILLED", payload: response.data });
    }
    catch (error) {
      dispatch({ type: "ADD_CASE_GENERATED_REJECTED", payload: error.response });
    }
  };

export const getCaseDetailsListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_CASE_DETAILS_LISTBYID_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Case-Details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_CASE_DETAILS_LISTBYID_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    }
    catch (error) {
      dispatch({
        type: "GET_CASE_DETAILS_LISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };