import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCallLogsListById, saveCallLogsData, updateCallLogsData } from "../../redux/actions/CallLogsActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllStateList, getMasterUsertypeUserWiseList, getMasterUserUserTypeWiseList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddCallLogs = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});
  const [userTypeId, setUserTypeId] = useState(null);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    userPermissionReducer: {
      loadingUserPermission,
      userPermissionByUserIdLists,
      errorsUserPermission,
      userPermissionLists,
      changedUserPermissionStatus,
    },
    masterReducer: {
      loadingMaster,
      masterUsertypeLists,
      masterUserLists,
      errorsMaster,
    },
    callLogsReducer: {
      callLogsPermissions,
      callLogsLists,
      loadingCallLogs,
      errorsCallLogs,
      callLogsCount,
      changedCallLogsStatus,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getMasterUsertypeUserWiseList(auth?.token));
  }, [auth]);


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
    }
  }, [errors, dispatch]);

  useEffect(() =>{
    if(userTypeId)
    {
      dispatch(getMasterUserUserTypeWiseList(auth?.token, userTypeId));
    }
  },[userTypeId])



  useEffect(() => {
    setFormData({})
    if(editId) {
      dispatch(getCallLogsListById(auth?.token, editId));
    }
  }, [auth, editId]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId)
    {
      dispatch(updateCallLogsData(auth?.token, formData,editId));
    }
    else
    {
      dispatch(saveCallLogsData(auth?.token, formData));
    }
  };

  useEffect(()=>{
    if(editId && callLogsLists && Object.keys(callLogsLists).length > 0) {
      setFormData({
        user_type_id: callLogsLists?.user_type_id,
        request_id: callLogsLists?.request_id,
      });
    }
  },[callLogsLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);

  useEffect(() => {
    if (errorsMaster && Object.keys(errorsMaster).length > 0) {
      if(errorsMaster?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        onHide();
      }
    }
  }, [errorsMaster]);

  useEffect(() => {
    if (changedCallLogsStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedCallLogsStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
    }
  }, [changedCallLogsStatus, dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    // console.log(selectedData);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0]?.id
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };


  return (
    <Modal show={show} onHide={onHide} size="md" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && callLogsLists && Object.keys(callLogsLists).length > 0) ? t("Update Call Logs") : t("Add Call Logs")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {errorsCallLogs?.data?.message && (
              <div className="alert alert-danger">
                {errorsCallLogs?.data?.message}
              </div>
            )}
          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("User Type")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterUsertypeLists}
                  values={masterUsertypeLists.filter((state) => state.id === formData.user_type_id)}
                  name="user_type_id"
                  labelField={"name"}
                  valueField={"id"}
                  loading={loadingMaster}
                  searchBy="name"
                  onChange={(selectedData) => {
                    selectHandler(selectedData, "user_type_id");
                    setUserTypeId(selectedData[0]?.id);
                  }}
                  styles={customSelectStyles}
                />
                
                {errorsCallLogs?.data?.user_type_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsCallLogs?.data?.user_type_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("User")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterUserLists.map(user => ({
                    ...user,
                    label: `${user.name} || ${user?.citiesAdminUsers?.city_name}`,
                  }))}
                  values={masterUserLists.filter((state) => state.id === formData.request_id)}
                  name="request_id"
                  labelField={"label"}
                  valueField={"id"}
                  loading={loadingMaster}
                  searchBy="label"
                  onChange={(selectedData) => {
                    selectHandler(selectedData, "request_id");
                    setUserId(selectedData[0]?.id);
                  }}
                  styles={customSelectStyles}
                />
                
                {errorsCallLogs?.data?.request_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsCallLogs?.data?.request_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {(editId && callLogsLists && Object.keys(callLogsLists).length > 0) ? t("Update") : t("Add")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      {loadingCallLogs && <Preloader />}
    </Modal>
  );
};

export default AddCallLogs;
