import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button ,Modal ,Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import PageTitle from "../../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import RoundedPagination from "../../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../../redux/actions/AuthActions";
import useDebounce from "../../../hooks/useDebounce";
import {getDisplayDateTimeFormat} from '../../../util/generalUtills'
import { deleteUserData, getActiveUserList, logoutUserDevice, resendEmailConfirmationId, updateUserData, updateUserStatusData } from "../../../redux/actions/UserActions";
import Preloader from "../../../Components/Preloader";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from 'react-csv';
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { apiURL } from "../../../axios";

const filterOptions = ["Equal","Contains", "Greater", "Less"];


const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    userReducer: {
      usersPermissions,
      userLists,
      loadingUsers,
      changedUserStatus,
      errorsUsers,
      userCount,
      changeUserStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;

  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState({ show: false, key: "", type: "", value: "" });


  const fetchVehicleList = useCallback(() => {
    dispatch({ type: 'RESET_DELETE_USER_STATUS' });
    if (auth?.token) {
      dispatch(getActiveUserList(auth.token, paginate, page, search, sort,filters));
    }
  }, [auth?.token, paginate, page, search, sort,filters, dispatch]);

  useDebounce(fetchVehicleList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    console.log("details: ",userLists);
  },[])


  const csvHead = [['SR No.', 'Name', 'Contact Number', 'User Type', 
    'Reporting User', 'City', 'Username', 'Official Email', 
    'Created By', 'Registered On']
  ]
  const csvData = userLists.map((cv, index) => {
    
    return [
      index + 1,
      cv?.name,
      cv?.phone || '',
      cv?.adminUserType?.name || '',
      cv?.reportingUser?.name || '',
      cv?.citiesAdminUsers?.city_name || '',
      cv?.username || '',
      cv?.official_mail_id || '',
      cv?.createdByAdminUsers?.name || '',
      getDisplayDateTimeFormat(cv?.createdAt)
    ];
  });
  const exportData = csvHead.concat(csvData);


  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors,errorsUsers])


  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? userCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const resendConfirmationMail = (id,email) => {
    swal.fire({
      title: "Are you sure?",
      text: `You want to send user details on Email : ${email}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, send it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(resendEmailConfirmationId(auth?.token, id));
      }
    });
    
  };

  useEffect(() => {
    if(changeUserStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changeUserStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATUS' });
      dispatch(getActiveUserList(auth?.token, paginate, page, search, sort,filters));
    }
  },[changeUserStatus])

  const handleFilterClick = (key) => {
    if (!filters[key]) {
      setFilters((prev) => ({
        ...prev,
        [key]: { type: "", value: "" },
      }));
    }
  
    setModal({ 
      show: true, 
      key, 
      type: filters[key]?.type || "", 
      value: filters[key]?.value || "" 
    });
  };

  const handleModalClose = (key) => {
    setModal({ show: false, key: "", type: "", value: "" });
  };

  const handleRemoveFilter = (key) => {
    setModal({ show: false, key: "", type: "", value: "" });
    setFilters((prev) => ({
      ...prev,
      [modal.key]: { type: "", value: "" },
    }));
    handleModalClose();
  };

  const handleApplyFilter = () => {
    setFilters((prev) => ({
      ...prev,
      [modal.key]: { type: modal.type, value: modal.value },
    }));
    handleModalClose();
  };

  useEffect(() => {
    console.log(filters);
    dispatch(getActiveUserList(auth.token, paginate, page, search, sort,filters));
  },[filters])

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUserData(auth?.token,id))
      }
    });
  };

  const handleStatusChangeRequest = (id, newStatus) => {
    swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${newStatus}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateUserStatusData(auth.token, id, newStatus));
      }
    });
  };


  useEffect(() => {
    if(changedUserStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedUserStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_USER_STATUS' });
      dispatch(getActiveUserList(auth?.token, paginate, page, search, sort));
    }
    else if(changedUserStatus?.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedUserStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_USER_STATUS' });
      dispatch(getActiveUserList(auth?.token, paginate, page, search, sort));
    }
  },[changedUserStatus])

  useEffect(() => {
      document.title = `Active Users :: ${auth?.generalSettings?.title}`;
    }, []);

  const printTable = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write(`<html><head><style type="text/css">'+
      '#backgroundImage{'+
        'min-height: 50vh;'+
        'position: relative;'+
        'margin: 0;'+
        '-webkit-print-color-adjust: exact !important; '+
        'color-adjust: exact !important;'+
        '}'+
        '#backgroundImage:before {'+
        'content: "";'+
        'position: absolute;'+
        'z-index: -1;'+
        'top: 0;'+
        'bottom: 0;'+
        'left: 0;'+
        'right: 0;'+
        'opacity: 0.04;'+
        'background:'+
            'url(\'${apiURL}logo.jpg\')'+
        '}'+
        '</style></head><body onload="window.print()" id="backgroundImage"><div align="center ">'+document.title+'</div><div class="main1"><table border="1" cellspacing="0">`);
    printWindow.document.write(document.getElementById('table-to-print').innerHTML);
    printWindow.document.write('</table></div><div align="center">This is a system generated report it does not require any signature or stamp</div><div align="right"><img src="logo.jpg" style="opacity: 0.1"></div></body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    
  };

  const userLogout = (id,imeiUsers) => {
    swal.fire({
      title: "Are you sure?",
      text: `You want to logout from ${imeiUsers?.brand} ${imeiUsers?.model} ${imeiUsers?.deviceType}`,
        
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, logout !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logoutUserDevice(auth?.token, id));
      }
    });
    
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Active User"), path: "/Users", active: true },
        ]}
        title={t("Active User")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                    {/* <input
                      type="search"
                      placeholder="Search..."
                      className="form-control ms-1"
                      autoComplete="off"
                      value={search}
                      onChange={handleSearchChange}
                    /> */}
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        {(Object.keys(filters).length>0) && (
                          <>
                            <Button variant="warning" onClick={() => setFilters({})}>
                              <i className="mdi mdi-filter-off me-1"></i>Clear All Filter
                            </Button>
                          </>
                        )}

                        {usersPermissions?.add && (
                          <>
                          <Link to="/Add-User">
                            <Button variant="success">
                              <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                            </Button>
                          </Link>
                          </>
                        )}

                        {usersPermissions?.print && (
                          <>
                            <Button variant="dark" onClick={printTable}>
                              <i className="mdi mdi-printer me-1"></i>Print
                            </Button>
                          </>
                        )}
                        
                        {usersPermissions?.excel && (
                          <>
                            <CSVLink
                              filename="active-users-reports.csv"
                              data={exportData}
                              className="btn btn-info text-white ms-1"
                              title="Export To CSV"
                            >
                              <i className="mdi mdi-file-excel me-1"></i>
                              Excel
                            </CSVLink>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  id="table-to-print"
                  size="sm"
                  striped
                  responsive
                >
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>SR No.</th>
                      <th>
                        Name
                        {usersPermissions?.filter && (
                          <>
                          <Button variant="link" onClick={() => handleFilterClick("name")}><FontAwesomeIcon icon={faFilter} /></Button>
                          </>
                        )}
                      </th>
                      <th>
                        Contact
                        {usersPermissions?.filter && (
                          <>
                          <Button variant="link" onClick={() => handleFilterClick("phone")}><FontAwesomeIcon icon={faFilter} /></Button>
                          </>
                        )}
                      </th>
                      
                      <th>
                        User Type
                        {usersPermissions?.filter && (
                          <>
                          <Button variant="link" onClick={() => handleFilterClick("usertype")}><FontAwesomeIcon icon={faFilter} /></Button>
                          </>
                        )}
                      </th>
                      <th>
                        Reporting User
                        {usersPermissions?.filter && (
                          <>
                          <Button variant="link" onClick={() => handleFilterClick("reportingUser")}><FontAwesomeIcon icon={faFilter} /></Button>
                          </>
                        )}
                      </th>
                      <th>
                        City
                        {usersPermissions?.filter && (
                          <>
                          <Button variant="link" onClick={() => handleFilterClick("city")}><FontAwesomeIcon icon={faFilter} /></Button>
                          </>
                        )}
                        
                      </th>
                      <th>
                        Username
                        {usersPermissions?.filter && (
                          <>
                          <Button variant="link" onClick={() => handleFilterClick("username")}><FontAwesomeIcon icon={faFilter} /></Button>
                          </>
                        )}
                      </th>
                      <th>
                        Attendance
                        {usersPermissions?.filter && (
                          <>
                          <Button variant="link" onClick={() => handleFilterClick("attendance")}><FontAwesomeIcon icon={faFilter} /></Button>
                          </>
                        )}
                      </th>
                      <th>Device Details</th>
                      <th>Created By</th>
                      <th>Registered On</th>
                      {(usersPermissions?.edit===true || usersPermissions?.delete===true) && (
                        <th>Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {userLists.length === 0 ? (
                      <tr>
                        <td colSpan="9">No data found</td>
                      </tr>
                    ) : (<>
                      {userLists?.map?.((item, index) => {
                        return (
                          <tr key={item.id}>
                            <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                            
                            <td>{item?.name}</td>
                            <td>{item?.phone}</td>
                            
                            <td>{item?.adminUserType?.name}</td>
                            <td>{item?.reportingUser?.name}</td>
                            <td>{item?.citiesAdminUsers?.city_name}</td>
                            <td>
                              <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-created-at">
                                      {`${item?.password}`}
                                    </Tooltip>
                                  }
                                >
                                <span>
                                Username : <span style={{textTransform:'lowercase'}}>{item?.username.toLowerCase()}</span>
                                <br />
                                Email : <span style={{textTransform:'lowercase'}}>{item?.official_mail_id}</span>
                                </span>
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-created-at">
                                      {`Time : ${item?.attendance_time}`}<br/>
                                      {`Type : ${item?.attendance_type}`}
                                    </Tooltip>
                                  }
                                >
                                <span className={`badge ${item?.attendance=='Enable' ? 'badge-success' : 'badge-danger'}`}>
                                {item?.attendance}
                                </span>
                              </OverlayTrigger>
                            </td>
                            <td>Version : {item?.imeiUsers?.appVersion}<br/>{item?.imeiUsers?.brand} {item?.imeiUsers?.model} ({item?.imeiUsers?.mobileVersion})</td>
                            <td>{item?.createdByAdminUsers?.name}</td>
                            <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                            {(usersPermissions?.edit===true || usersPermissions?.delete===true) && (
                              <td>
                                {usersPermissions?.edit && (
                                  <>
                                    {item?.status === "Active" && (
                                      <>
                                        <Link to="#" className="action-icon text-success" onClick={() => handleStatusChangeRequest(item?.id , "In-Active")}>
                                          {" "}
                                          <i className="mdi mdi-eye"></i>
                                        </Link>
                                      </>
                                    )}
                                    {item?.status === "In-Active" && (
                                      <>
                                        <Link to="#" className="action-icon text-warning" onClick={() => handleStatusChangeRequest(item?.id, "Active")}>
                                          {" "}
                                          <i className="mdi mdi-eye-off"></i>
                                        </Link>
                                      </>
                                    )}
                                    {item?.imei != null && (<>
                                      <Link onClick={() => userLogout(item?.id,item?.imeiUsers)} className="action-icon text-warning"> 
                                        <i className="mdi mdi-logout"></i>
                                      </Link>
                                    </>)}
                                    <Link to={`/Update-User/${item?.id}`} className="action-icon text-info"> 
                                      <i className="mdi mdi-square-edit-outline"></i>
                                    </Link>
                                    <Link onClick={() => resendConfirmationMail(item?.id,item?.email)} className="action-icon text-warning"> 
                                      <i className="mdi mdi-email-outline"></i>
                                    </Link>
                                  </>
                                )}
                                {usersPermissions?.delete && (
                                  <>
                                    <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                                      {" "}
                                      <i className="mdi mdi-delete"></i>
                                    </Link>
                                  </>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}</>
                    )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Total Records <strong>{userCount}</strong><br/>Page <strong>{page}</strong> of <strong>{Math.ceil(userCount / paginate)}</strong> </span>
                <RoundedPagination
                  currentPage={page}
                  totalPages={Math.ceil(userCount / paginate)}
                  onPageChange={(pageNumber) => setPage(pageNumber)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingUsers && (<Preloader/>)}

      <Modal 
    show={modal.show} 
    onHide={handleModalClose} 
    size="sm" // Smaller size
    centered // Centers the modal on the screen
    dialogClassName="compact-modal" // Custom class for additional styling
  >
    <Modal.Header closeButton className="p-2">
      <Modal.Title style={{ fontSize: '1rem' }}>Filter {modal.key}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <Form.Group>
          <Form.Label style={{ fontSize: '0.85rem' }}>Filter Type</Form.Label>
          <Form.Select
            value={modal.type}
            onChange={(e) => setModal((prev) => ({ ...prev, type: e.target.value }))}
            size="sm" // Smaller select input
            style={{ fontSize: '0.85rem' }}
          >
            <option value="">Select Type</option>
            {filterOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label style={{ fontSize: '0.85rem' }}>Value</Form.Label>
          <Form.Control
            type="text"
            value={modal.value}
            onChange={(e) => setModal((prev) => ({ ...prev, value: e.target.value }))}
            size="sm" // Smaller input
            style={{ fontSize: '0.85rem' }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="p-1">
        <Button variant="outline-secondary" size="sm" onClick={() => handleRemoveFilter(modal.key)}>
          <FontAwesomeIcon icon={faTimes} /> Reset
        </Button>
        <Button variant="primary" size="sm" onClick={handleApplyFilter}>
          <FontAwesomeIcon icon={faCheck} /> Apply
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default Dashboard;