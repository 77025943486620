import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteCityData, getCityList, updateCityStatusData } from "../../redux/actions/CityActions";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import Preloader from "../../Components/Preloader";
import { getAttendanceDailyList } from "../../redux/actions/AttendanceActions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CSVLink } from 'react-csv';
import { getDisplayDateTimeFormat,getDateTimeMysqlFormatYm } from "../../util/generalUtills";
import { apiURL } from "../../axios";
import AddAttendnace from "../../Components/FormComponent/AddAttendnace";


const DailyAttendance = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');


  const hideAttendanceModel = () => {
    setShowAttendanceModel(false);
    dispatch(getAttendanceDailyList(auth.token, paginate, page, search, sort,month));
  };
  
  const handleImageClick = (imageUrl) => {
    setImageUrl(imageUrl);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setImageUrl("")
  };


  const {
    AuthReducer: { auth,errors,loading },
    attendanceReducers: {
      dailyAttendancePermissions,
      dailyAttendanceLists,
      loadingAttendance,
      errorsAttendance,
      dailyAttendanceCount,
    },
  } = useSelector((city) => city);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(100);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const [month, setMonth] = useState(new Date());
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  const [showAttendanceModel, setShowAttendanceModel] = useState(false);
  
  const fetchCityList = useCallback(() => {
    if (auth?.token) {
      dispatch(getAttendanceDailyList(auth.token, paginate, page, search, sort,month));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchCityList, 500, [auth, paginate, page, search, sort]);

  const csvHead = [['SR No.', 'Name', 'Contact Number', 'User Type',
    'City', 'Working Type', 'Login Time', 'Login Address', 'Logout Time', 'Logout Address']
  ]
  const csvData = dailyAttendanceLists.map((cv, index) => {
    
    return [
      index + 1,
      cv?.name,
      cv?.phone || '',
      cv?.user_types || '',
      cv?.city + "," + cv?.states || '',
      cv?.type || 'Absent',
      cv?.createdAt ? getDisplayDateTimeFormat(cv?.createdAt) : '',
      cv?.login_address || '',
      cv?.logout_time!=null ? getDisplayDateTimeFormat(cv?.logout_time) : '',
      cv?.logout_address || ''
    ];
  });
  const exportData = csvHead.concat(csvData);
  
  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])

  const inputDateHandler = (value) => {
    //const { value, name } = e.target;
    //setFormData({ ...formData, [name]: value });
    dispatch({ type: 'GET_ATTENDANCE_RESET' });
    setMonth(value);
    dispatch(getAttendanceDailyList(auth.token, paginate, page, search, sort,value));
  };

  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? dailyAttendanceCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    document.title = `Daily Attendance :: ${auth?.generalSettings?.title}`;
  }, []);

  

  const printTable = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write(`<html><head><style type="text/css">'+
      '#backgroundImage{'+
        'min-height: 50vh;'+
        'position: relative;'+
        'margin: 0;'+
        '-webkit-print-color-adjust: exact !important; '+
        'color-adjust: exact !important;'+
        '}'+
        '#backgroundImage:before {'+
        'content: "";'+
        'position: absolute;'+
        'z-index: -1;'+
        'top: 0;'+
        'bottom: 0;'+
        'left: 0;'+
        'right: 0;'+
        'opacity: 0.04;'+
        'background:'+
            'url(\'${apiURL}logo.jpg\')'+
        '}'+
        '</style></head><body onload="window.print()" id="backgroundImage"><div align="center ">'+document.title+'</div><div class="main1"><table border="1" cellspacing="0">`);
    printWindow.document.write(document.getElementById('table-to-print').innerHTML);
    printWindow.document.write('</table></div><div align="center">This is a system generated report it does not require any signature or stamp</div><div align="right"><img src="logo.jpg" style="opacity: 0.1"></div></body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Attendance Report"), path: "/Attendance/Daily", active: false },
          { label: t("Daily Attendance"), path: "/Attendance/Daily", active: true },
        ]}
        title={t("Daily Attendance")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                {(dailyAttendancePermissions.filter ) && (<>
                    <Col lg={3}>
                    <Form.Group className="mb-3">
                      <DatePicker
                        selected={month}
                        onChange={(value) => inputDateHandler(value)}
                        dateFormat="yyyy-MM-dd" // Display the date in yyyy-MM-dd format
                        maxDate={(() => {
                          const date = new Date();
                          //date.setFullYear(date.getFullYear() - 0);  // Subtract 18 years
                          return date;
                        })()}
                        className="form-control"
                        withPortal
                        showYearDropdown
                        scrollableYearDropdown
                      />
                    </Form.Group>
                  </Col>
                  </>)}
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                    {(dailyAttendancePermissions.filter ) && (<>
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                      
                    </>)}
                      
                    </div>
                  </Col>
                  
                  
                  
                  <Col lg={6}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                      {(dailyAttendancePermissions.add ) && (<>
                        <Button variant="success" onClick={() => {
                          setShowAttendanceModel(true)
                          // setEditHospitalId(null)
                        }} >
                          <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                        </Button>
                      </>)}
                      {(dailyAttendancePermissions.print ) && (<>
                        <Button variant="dark" onClick={printTable}>
                          <i className="mdi mdi-printer me-1"></i>Print
                        </Button>
                      </>)}
                      {(dailyAttendancePermissions.excel ) && (<>
                        <CSVLink
                          filename="daily-attendance-reports.csv"
                          data={exportData}
                          className="btn btn-info text-white ms-1"
                          title="Export To CSV"
                        >
                          <i className="mdi mdi-file-excel me-1"></i>
                          Excel
                        </CSVLink>
                      </>)}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  id="table-to-print"
                  size="sm"
                  striped
                  responsive
                  >
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>User Type</th>
                      <th>City</th>
                      <th>Working Type</th>
                      <th>Login Time</th>
                      <th>Logout Time</th>
                      <th>Login Photo</th>
                      <th>Logout Photo</th>
                    </tr>
                  </thead>
                  <tbody>
                  {dailyAttendanceLists.length === 0 ? (
                    <tr>
                      <td colSpan="12">No data found</td>
                    </tr>
                  ) : (<>
                    {dailyAttendanceLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.name}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.user_types}</td>
                          <td>{item?.city}, {item?.states}</td>
                          <td>{
                            item?.type=="Present" ? 
                              <>
                                <Badge className={"bg-success"}>
                                  {item?.type}
                                </Badge>
                              </>
                              : item?.type=="Leave" 
                              ? 
                              <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-created-at">
                                        {`${item?.leave_remark}`}
                                      </Tooltip>
                                    }
                                  >
                                  <Badge className={"bg-warning"}>
                                  {item?.type}
                                  </Badge>
                                </OverlayTrigger>
                              </>
                              : 
                              <>
                                <Badge className={"bg-danger"}>
                                {item?.type}
                                </Badge>
                              </>
                              }</td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-created-at">
                                  {item?.login_address}
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block" tabIndex="0">
                                {item?.createdAt ? getDisplayDateTimeFormat(item?.createdAt) : ''}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-created-at">
                                  {item?.logout_address}
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block" tabIndex="0">
                              {item?.logout_time!=null ? getDisplayDateTimeFormat(item?.logout_time) : ''}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          {item?.createdAt != null ? (
                            <img 
                              className="avatar-md rounded" 
                              src={`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.login_image}`} 
                              onClick={() => handleImageClick(`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.login_image}`)} 
                              alt="Login Image"
                            />
                          ) : ''}
                          </td>
                          <td>
                          {item?.logout_time != null ? (
                            <img 
                              className="avatar-md rounded" 
                              src={`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.logout_image}`}
                              onClick={() => handleImageClick(`${auth?.generalSettings?.app_url}/uploads/uploads/${getDateTimeMysqlFormatYm(item?.createdAt)}/attendance/${item?.logout_image}`)} 
                              alt="Login Image"
                            />
                          ) : ''}
                          </td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(dailyAttendanceCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(dailyAttendanceCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddAttendnace show={showAttendanceModel} onHide={() => hideAttendanceModel()} />
      <Modal show={showModal} onHide={handleClose} size="md">
        <Modal.Body>
          <img
            src={imageUrl}
            alt="Full size"
            className="img-fluid"
          />
        </Modal.Body>
      </Modal>
      
      {loadingAttendance && (<Preloader/>)}
    </>
  );
};

export default DailyAttendance;
