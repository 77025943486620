const initialHospitalizationType = {
    hospitalizationTypePermissions:{},
    hospitalizationTypeLists: [],
    hospitalizationTypeStatus: [],
    changedHospitalizationTypeStatus : [],
    loadingHospitalizationType: false,
    errorsHospitalizationType: {}
}

const  hospitalizationTypeReducer = (hospitalizationType = initialHospitalizationType, action) => {
    switch (action.type) {
        case "GET_HOSPITALIZATIONTYPELIST_PENDING":
        case "ADD_HOSPITALIZATIONTYPE_PENDING":
        case "UPDATE_HOSPITALIZATIONTYPE_PENDING":
        case "UPDATE_HOSPITALIZATIONTYPE_STATUS_PENDING":
        case "DELETE_HOSPITALIZATIONTYPE_PENDING":
        case "GET_HOSPITALIZATIONTYPELISTBYID_PENDING":
            return { ...hospitalizationType, loadingHospitalizationType: true };

        case "GET_HOSPITALIZATIONTYPELIST_FULFILLED":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                hospitalizationTypeLists: action.payload,
                hospitalizationTypeCount: action.count,
                hospitalizationTypePermissions:action.permissions,
            };

        case "ADD_HOSPITALIZATIONTYPE_FULFILLED":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                changedHospitalizationTypeStatus: action.payload,
            };

        case "UPDATE_HOSPITALIZATIONTYPE_FULFILLED":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                changedHospitalizationTypeStatus: action.payload,
            };

        case "UPDATE_HOSPITALIZATIONTYPE_STATUS_FULFILLED":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                changedHospitalizationTypeStatus: action.payload,
            };

        case "GET_HOSPITALIZATIONTYPELISTBYID_FULFILLED":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                hospitalizationTypeLists: action.payload
            };

        case "DELETE_HOSPITALIZATIONTYPE_FULFILLED":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                changedHospitalizationTypeStatus: action.payload
            };

        case "RESET_DELETE_HOSPITALIZATIONTYPE_STATUS":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                hospitalizationTypeStatus:{},
                changedHospitalizationTypeStatus: [],
                errorsHospitalizationType:{}
            };

        case "GET_HOSPITALIZATIONTYPELIST_REJECTED":
        case "ADD_HOSPITALIZATIONTYPE_REJECTED":
        case "UPDATE_HOSPITALIZATIONTYPE_REJECTED":
        case "UPDATE_HOSPITALIZATIONTYPE_STATUS_REJECTED":
        case "DELETE_HOSPITALIZATIONTYPE_REJECTED":
        case "GET_HOSPITALIZATIONTYPELISTBYID_REJECTED":
            return {
                ...hospitalizationType,
                loadingHospitalizationType: false,
                errorsHospitalizationType: action.payload
            };
        default:
            return hospitalizationType;
    }
}

export default hospitalizationTypeReducer;