import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteLeaveData, getLeaveList, updateLeaveStatusData } from "../../redux/actions/LeaveActions";
import RoundedPagination from "../../Components/Paginate";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import AddLeave from "../../Components/FormComponent/AddLeave";

const LeaveIndex = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showCallDetailsModal, setShowCallDetailsModal] = useState(false);
  const [editLeaveId, setEditLeaveId] = useState(null);
  const [editLeaveData, setEditLeaveData] = useState([]);
  const {
    AuthReducer: { auth,errors,loading },
    leaveReducer: {
      leavePermissions,
      leaveLists,
      loadingLeave,
      errorsLeave,
      leaveCount,
      changedLeaveStatus,
    },
  } = useSelector((leave) => leave);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  const hideModel = () => {
    setShowModal(false);
    setEditLeaveId(null);
    dispatch(getLeaveList(auth.token, paginate, page, search, sort));
  };

  const hideCallDetailsModel = () => {
    setShowCallDetailsModal(false);
    setEditLeaveData([]);
  };
  
  const fetchLeaveList = useCallback(() => {
    if (auth?.token) {
      dispatch(getLeaveList(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchLeaveList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])



  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? leaveCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLeaveData(auth?.token,id))
      }
    });
  };

  const handleStatusChangeRequest = (id, newStatus) => {
    // swal.fire({
    //   title: "Are you sure?",
    //   text: `Do you want to change the status to ${newStatus}?`,
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#28bb4b",
    //   cancelButtonColor: "#f34e4e",
    //   confirmButtonText: "Yes, change it!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     dispatch(updateLeaveStatusData(auth.token, id, newStatus));
    //   }
    // });
    swal.fire({
      title: "Enter Remark for "+newStatus,
      input: "text",
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#4a4fea",
      cancelButtonColor: "#f34e4e",
      preConfirm: (remark) => {
        return new Promise((resolve, reject) => {
          if (remark.length==0) {
            reject("Please enter remark");
          } else {
            resolve(remark);
          }
        }).catch((error) => {
          swal.showValidationMessage(error);
        });
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateLeaveStatusData(auth.token, id, newStatus,result.value));
      }
    });
  };


  useEffect(() => {
    //console.log(changedLeaveStatus);
    if(changedLeaveStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedLeaveStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
      dispatch(getLeaveList(auth?.token, paginate, page, search, sort));
    }
    else if(changedLeaveStatus?.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedLeaveStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
      dispatch(getLeaveList(auth?.token, paginate, page, search, sort));
    }
  },[changedLeaveStatus])


  useEffect(() => {
    console.log('editLeaveData',editLeaveData);
  },[editLeaveData]);

  useEffect(() => {
      document.title = `Leave :: ${auth?.generalSettings?.title}`;
    }, []);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Leave"), path: "/Leave", active: true },
        ]}
        title={t("Leave")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                    {(leavePermissions?.filter ) && (<>
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                      </>)}
                      
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                      {(leavePermissions?.add ) && (<>
                        <Button variant="success" onClick={() => {
                          setShowModal(true)
                          setEditLeaveId(null)
                        }} >
                          <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                        </Button>
                      </>)}
                      {(leavePermissions?.print ) && (<>
                        <Button variant="dark">
                          <i className="mdi mdi-printer me-1"></i>Print
                        </Button>
                      </>)}
                      {(leavePermissions?.excel ) && (<>
                        <Button variant="info">
                          <i className="mdi mdi-file-excel me-1"></i>Excel
                        </Button>
                      </>)}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  size="sm"
                  striped
                  responsive
                  >
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>#</th>
                      <th>Employee</th>
                      <th>Leave Type</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Remark</th>
                      <th>Approval </th>
                      <th>Approved At</th>
                      <th>Approval Remark</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {leaveLists.length === 0 ? (
                    <tr>
                      <td colSpan="12">No data found</td>
                    </tr>
                  ) : (<>
                    {leaveLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.createdByLeave?.name}<br/>({item?.createdByLeave?.adminUserType?.name})</td>
                          <td>{item?.leave_type}</td>
                          <td>{item?.leave_start}</td>
                          <td>{item?.leave_end}</td>
                          <td>{item?.reason}</td>
                          <td>{item?.approveByLeave?.name}<br/>({item?.approveByLeave?.adminUserType?.name})</td>
                          <td>{item?.approveAt ? getDisplayDateTimeFormat(item?.approveAt) : ''}</td>
                          <td>{item?.approve_remark}</td>
                          <td>{item?.status === "Pending" ? <Badge className={"bg-warning"}>{item?.status}</Badge> : <> {item?.status === "Approved" ? <Badge className={"bg-success"}>{item?.status}</Badge> : <Badge className={"bg-danger"}>{item?.status}</Badge>}</>}</td>
                          <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                          
                          {(leavePermissions?.edit || leavePermissions?.delete) && (<>
                            <td>
                              {(leavePermissions?.edit ) && (<>
                                {item?.reporting_id==auth?.data.id && (<>
                                  {item?.status === "Pending" && (
                                    <>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip-created-at">
                                            Approve
                                          </Tooltip>
                                        }
                                      >
                                      <Link to="#" className="action-icon text-success" onClick={() => handleStatusChangeRequest(item?.id , "Approved")}>
                                        {" "}
                                        <i className="mdi mdi-account-check"></i>
                                      </Link>
                                      </OverlayTrigger>

                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip-created-at">
                                            Reject
                                          </Tooltip>
                                        }
                                      >
                                        <Link to="#" className="action-icon text-danger" onClick={() => 
                                          handleStatusChangeRequest(item?.id, "Reject")
                                        }>
                                          {" "}
                                          <i className="mdi mdi-account-cancel"></i>
                                        </Link>
                                      </OverlayTrigger>
                                    </>
                                  )}
                                </>)}
                                  
                                {item?.status === "Pending" && (
                                  <>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-created-at">
                                          Edit
                                        </Tooltip>
                                      }
                                    >
                                    <Link to="#" onClick={() => {
                                        setShowModal(true)
                                        setEditLeaveId(item?.id)
                                      }} className="action-icon text-info"> 
                                        <i className="mdi mdi-square-edit-outline"></i>
                                      </Link>
                                    </OverlayTrigger>
                                    {(leavePermissions?.delete) && (<>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip-created-at">
                                            Delete
                                          </Tooltip>
                                        }
                                      >
                                      <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                                        {" "}
                                        <i className="mdi mdi-delete"></i>
                                      </Link>
                                      </OverlayTrigger>
                                    </>)}
                                  </>
                                )}
                                
                              </>)}
                              
                              
                            </td>
                          </>)}
                          
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(leaveCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(leaveCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddLeave show={showModal} onHide={() => hideModel()} editId={editLeaveId}  />

      
      {loadingLeave && (<Preloader/>)}
    </>
  );
};

export default LeaveIndex;
