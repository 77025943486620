const initialState = {
    hospitalPermissions:{},
    hospitalLists: [],
    hospitalStatus: [],
    changedHospitalStatus : [],
    loadingHospital: false,
    errorsHospital: {}
}

const  hospitalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_HOSPITALLIST_PENDING":
        case "ADD_HOSPITAL_PENDING":
        case "UPDATE_HOSPITAL_PENDING":
        case "UPDATE_HOSPITAL_STATUS_PENDING":
        case "DELETE_HOSPITAL_PENDING":
        case "GET_HOSPITALLISTBYID_PENDING":
            return { ...state, loadingHospital: true };

        case "GET_HOSPITALLIST_FULFILLED":
            return {
                ...state,
                loadingHospital: false,
                hospitalLists: action.payload,
                hospitalCount: action.count,
                hospitalPermissions:action.permissions,
            };

        case "ADD_HOSPITAL_FULFILLED":
            return {
                ...state,
                loadingHospital: false,
                changedHospitalStatus: action.payload,
            };

        case "UPDATE_HOSPITAL_FULFILLED":
            return {
                ...state,
                loadingHospital: false,
                changedHospitalStatus: action.payload,
            };

        case "UPDATE_HOSPITAL_STATUS_FULFILLED":
            return {
                ...state,
                loadingHospital: false,
                changedHospitalStatus: action.payload,
            };

        case "GET_HOSPITALLISTBYID_FULFILLED":
            return {
                ...state,
                loadingHospital: false,
                hospitalLists: action.payload
            };

        case "DELETE_HOSPITAL_FULFILLED":
            return {
                ...state,
                loadingHospital: false,
                changedHospitalStatus: action.payload
            };

        case "RESET_DELETE_HOSPITALS_STATUS":
            return {
                ...state,
                loadingHospital: false,
                changedHospitalStatus: [],
                errorsHospital:{}
            };

        case "GET_HOSPITALLIST_REJECTED":
        case "ADD_HOSPITAL_REJECTED":
        case "UPDATE_HOSPITAL_REJECTED":
        case "UPDATE_HOSPITAL_STATUS_REJECTED":
        case "DELETE_HOSPITAL_REJECTED":
        case "GET_HOSPITALLISTBYID_REJECTED":
            return {
                ...state,
                loadingHospital: false,
                errorsHospital: action.payload
            };

        default:
            return state;
    }
}

export default hospitalReducer;