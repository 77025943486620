const initialRoomType = {
    roomTypePermissions:{},
    roomTypeLists: [],
    roomTypeStatus: [],
    changedRoomTypeStatus : [],
    loadingRoomType: false,
    errorsRoomType: {}
}

const  roomTypeReducer = (roomType = initialRoomType, action) => {
    switch (action.type) {
        case "GET_ROOMTYPELIST_PENDING":
        case "ADD_ROOMTYPE_PENDING":
        case "UPDATE_ROOMTYPE_PENDING":
        case "UPDATE_ROOMTYPE_STATUS_PENDING":
        case "DELETE_ROOMTYPE_PENDING":
        case "GET_ROOMTYPELISTBYID_PENDING":
            return { ...roomType, loadingRoomType: true };

        case "GET_ROOMTYPELIST_FULFILLED":
            return {
                ...roomType,
                loadingRoomType: false,
                roomTypeLists: action.payload,
                roomTypeCount: action.count,
                roomTypePermissions:action.permissions,
            };

        case "ADD_ROOMTYPE_FULFILLED":
            return {
                ...roomType,
                loadingRoomType: false,
                changedRoomTypeStatus: action.payload,
            };

        case "UPDATE_ROOMTYPE_FULFILLED":
            return {
                ...roomType,
                loadingRoomType: false,
                changedRoomTypeStatus: action.payload,
            };

        case "UPDATE_ROOMTYPE_STATUS_FULFILLED":
            return {
                ...roomType,
                loadingRoomType: false,
                changedRoomTypeStatus: action.payload,
            };

        case "GET_ROOMTYPELISTBYID_FULFILLED":
            return {
                ...roomType,
                loadingRoomType: false,
                roomTypeLists: action.payload
            };

        case "DELETE_ROOMTYPE_FULFILLED":
            return {
                ...roomType,
                loadingRoomType: false,
                changedRoomTypeStatus: action.payload
            };

        case "RESET_DELETE_ROOMTYPE_STATUS":
            return {
                ...roomType,
                loadingRoomType: false,
                roomTypeStatus:{},
                changedRoomTypeStatus: [],
                errorsRoomType:{}
            };

        case "GET_ROOMTYPELIST_REJECTED":
        case "ADD_ROOMTYPE_REJECTED":
        case "UPDATE_ROOMTYPE_REJECTED":
        case "UPDATE_ROOMTYPE_STATUS_REJECTED":
        case "DELETE_ROOMTYPE_REJECTED":
        case "GET_ROOMTYPELISTBYID_REJECTED":
            return {
                ...roomType,
                loadingRoomType: false,
                errorsRoomType: action.payload
            };
        default:
            return roomType;
    }
}

export default roomTypeReducer;