import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getInsuranceManagerListById, saveInsuranceManagerData, updateInsuranceManagerData } from "../../redux/actions/InsuranceManagerActions";
import FormInput from "../../Components/FormInput";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { getAllCityStateWiseList, getAllStateList, getMasterDesignationList, getMasterInsuranceList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import '../../styles/common.css'
import AddDesignation from "./AddDesignation";

const API_KEY = "AIzaSyD5L3M2Nl4wn3coWVJlI06RjT50l44FMu8";

const AddInsuranceManagerManager = ({ show, onHide, editId }) => {
  const { t } = useTranslation();
  const [showModel, setShowModel] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [placeData, setPlaceData] = useState({});
  const [formData, setFormData] = useState({});

  const [showDesignationModel, setShowDesignationModel] = useState(false);
  const hideDesignationModel = () => {
    setShowDesignationModel(false);
    dispatch(getMasterDesignationList(auth.token));
  };


  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    insuranceManagerReducer: {
      loadingInsuranceManager,
      errorsInsuranceManager,
      insuranceManagerLists,
      changedInsuranceManagerStatus,
    },
    masterReducer: {
      loadingMaster,
      masterInsuranceList,
      masterDesignationList
    },
  } = useSelector((state) => state);

  const [validated, setValidated] = useState(false);
  

  useEffect(() => {
    dispatch(getMasterInsuranceList(auth.token));
    dispatch(getMasterDesignationList(auth.token));
    setFormData({})
    if(editId) {
      dispatch(getInsuranceManagerListById(auth?.token, editId));
    }
  }, [auth, editId]);

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(editId){
      dispatch(updateInsuranceManagerData(auth?.token, formData, editId));
    } else {
      dispatch(saveInsuranceManagerData(auth?.token, formData));
    }
  };

  
  useEffect(() => {
    if(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) {
      setFormData({
        name: insuranceManagerLists?.name,
        email: insuranceManagerLists?.email,
        mobile_number: insuranceManagerLists?.mobile_number,
        insurance_id: insuranceManagerLists?.insuranceManager_insurance?.id,
        designation_id: insuranceManagerLists?.insuranceManager_designation?.id,
      });
    }
  }, [insuranceManagerLists]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && errors?.status === 401) {
      dispatch(logout());
      toast.error(errors?.data?.message);
      onHide(); // Close modal on logout
    }
  }, [errors, dispatch, onHide]);


  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectMobileHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const selectPincodeHandler = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,6}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
    
  };

  const selectBlockSpecialHandler = (e) => {
    const { value, name } = e.target;
    if (/^[a-z0-9]{0,30}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
        'official_mail_id': `${value}@asg.com`,
      }));
    }
  };

  
  useEffect(() => {
    if (changedInsuranceManagerStatus?.success) {
    onHide()
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedInsuranceManagerStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_INSURANCE_MANAGER_STATUS' });
      
    }
  }, [changedInsuranceManagerStatus, dispatch]);


  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update Insurance Manager") : t("Add Insurance Manager")}</Modal.Title>
      </Modal.Header>
        <Modal.Body>
            {errorsInsuranceManager?.data?.message && (
              <div className="alert alert-danger">
                {errorsInsuranceManager?.data?.message}
              </div>
            )}
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Insurance Company")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                <Select
                  options={masterInsuranceList}
                  values={masterInsuranceList?.filter(state => state.id === formData.insurance_id)}
                  name="insurance_id"
                  labelField={"name"}
                  valueField={"id"}
                  searchBy={"name"}
                  loading={loadingMaster}
                  onChange={(selectedData) => {
                    selectHandler2(selectedData[0]?.id, "insurance_id")
                  }}
                />
                
                {errorsInsuranceManager?.data?.insurance_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsInsuranceManager?.data?.insurance_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>{t("Designation")} <span style={{color:'red',fontSize:15}}>*</span></Form.Label>
                  <button 
                    className="btn btn-link p-0 text-decoration-none" 
                    onClick={() => setShowDesignationModel(true)}
                    type="button"
                  >
                    {t("Add New Designation")}
                  </button>
                </div>
                <Select
                  options={masterDesignationList}
                  values={masterDesignationList?.filter(state => state.id === formData.designation_id)}
                  name="designation_id"
                  labelField={"name"}
                  valueField={"id"}
                  searchBy={"name"}
                  loading={loadingMaster}
                  onChange={(selectedData) => {
                    selectHandler2(selectedData[0]?.id, "designation_id")
                  }}
                />
                
                {errorsInsuranceManager?.data?.designation_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsInsuranceManager?.data?.designation_id}</p>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col lg={6}>
              <FormInput
                label={t("Name")}
                type="text"
                errors={errorsInsuranceManager?.data}
                value={formData.name}
                name="name"
                required={true}
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
            </Col>
            
            <Col lg={6}>
              <FormInput
                label={t("Email Address")}
                type="text"
                errors={errorsInsuranceManager?.data}
                value={formData.email}
                name="email"
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                label={t("Mobile Number")}
                type="text"
                errors={errorsInsuranceManager?.data}
                value={formData.mobile_number}
                name="mobile_number"
                required={true}
                onChange={selectMobileHandler}
                containerClass={"mb-3"}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            {loading ? 
              <Button color="primary" disabled>
                <Spinner animation="border" size="sm" />
                {t("Please wait ...")}
              </Button>
            :
              <Button type="button" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {(editId && insuranceManagerLists && Object.keys(insuranceManagerLists).length > 0) ? t("Update") : t("Add")}
              </Button>
            }
          </div>
        
      </Modal.Body>
      <AddDesignation show={showDesignationModel} onHide={() => hideDesignationModel()}  />
      {loadingInsuranceManager && <Preloader />}
    </Modal>
  );
};

export default AddInsuranceManagerManager;
