const initialUserType = {
    userTypePermissions:{},
    userTypeLists: [],
    userTypeStatus: [],
    changedUserTypeStatus : [],
    loadingUserType: false,
    errorsUserType: {}
}

const  userTypeReducer = (state = initialUserType, action) => {
    switch (action.type) {
        case "GET_USERTYPELIST_PENDING":
        case "ADD_USERTYPE_PENDING":
        case "UPDATE_USERTYPE_PENDING":
        case "DELETE_USERTYPE_PENDING":
        case "GET_USERTYPELISTBYID_PENDING":
        case "UPDATE_USERTYPE_STATUS_PENDING":
            return { ...state, loadingUserType: true };

        case "GET_USERTYPELIST_FULFILLED":
            return {
                ...state,
                loadingUserType: false,
                userTypeLists: action.payload,
                userTypeCount: action.userTypeCount,
                userTypePermissions:action.permissions
            };

        case "ADD_USERTYPE_FULFILLED":
            return {
                ...state,
                loadingUserType: false,
                changedUserTypeStatus: action.payload,
            };

        case "UPDATE_USERTYPE_FULFILLED":
            return {
                ...state,
                loadingUserType: false,
                changedUserTypeStatus: action.payload,
            };

        case "UPDATE_USERTYPE_STATUS_FULFILLED":
            return {
                ...state,
                loadingUserType: false,
                changedUserTypeStatus: action.payload,
            };

        case "GET_USERTYPELISTBYID_FULFILLED":
            return {
                ...state,
                loadingUserType: false,
                userTypeLists: action.payload
            };

        case "DELETE_USERTYPE_FULFILLED":
            return {
                ...state,
                loadingUserType: false,
                changedUserTypeStatus: action.payload
            };

        case "RESET_DELETE_USERTYPE_STATUS":
            return {
                ...state,
                loadingUserType: false,
                changedUserTypeStatus: [],
                errorsUserType:{}
            };

        case "GET_USERTYPELIST_REJECTED":
        case "ADD_USERTYPE_REJECTED":
        case "UPDATE_USERTYPE_REJECTED":
        case "DELETE_USERTYPE_REJECTED":
        case "GET_USERTYPELISTBYID_REJECTED":
        case "UPDATE_USERTYPE_STATUS_REJECTED":
            return {
                ...state,
                loadingUserType: false,
                errorsUserType: action.payload
            };

        default:
            return state;
    }
}

export default userTypeReducer;