const initialState = {
    generalSettingLists: [],
    generalSettingPermissions: [],
    generalSettingStatus: [],
    changedGeneralSettingStatus : [],
    loadingGeneralSetting: false,
    errorsGeneralSetting: {}
}

const  generalSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_GENERAL_SETTING_LIST_PENDING":
        case "ADD_GENERAL_SETTING_PENDING":
            return { ...state, loadingGeneralSetting: true };

        case "GET_GENERAL_SETTING_LIST_FULFILLED":
            return {
                ...state,
                loadingGeneralSetting: false,
                generalSettingLists: action.payload,
                generalSettingPermissions: action.permissions,
                generalSettingCount: action.generalSettingCount
            };

        case "ADD_GENERAL_SETTING_FULFILLED":
            return {
                ...state,
                loadingGeneralSetting: false,
                changedGeneralSettingStatus: action.payload,
            };

        case "RESET_DELETE_GENERAL_SETTING_STATUS":
            return {
                ...state,
                loadingGeneralSetting: false,
                changedGeneralSettingStatus: [],
                errorsGeneralSetting:{}
            };

        case "GET_GENERAL_SETTING_LIST_REJECTED":
        case "ADD_GENERAL_SETTING_REJECTED":
            return {
                ...state,
                loadingGeneralSetting: false,
                errorsGeneralSetting: action.payload
            };

        default:
            return state;
    }
}

export default generalSettingReducer;