const initialCity = {
    cityPermissions:{},
    cityLists: [],
    cityStatus: [],
    changedCityStatus : [],
    loadingCity: false,
    errorsCity: {}
}

const  cityReducer = (state = initialCity, action) => {
    switch (action.type) {
        case "GET_CITYLIST_PENDING":
        case "ADD_CITY_PENDING":
        case "UPDATE_CITY_PENDING":
        case "UPDATE_CITY_STATUS_PENDING":
        case "DELETE_CITY_PENDING":
        case "GET_CITYLISTBYID_PENDING":
            return { ...state, loadingCity: true };

        case "GET_CITYLIST_FULFILLED":
            return {
                ...state,
                loadingCity: false,
                cityLists: action.payload,
                cityCount: action.cityCount,
                cityPermissions:action.permissions,
            };

        case "ADD_CITY_FULFILLED":
            return {
                ...state,
                loadingCity: false,
                changedCityStatus: action.payload,
            };

        case "UPDATE_CITY_FULFILLED":
            return {
                ...state,
                loadingCity: false,
                changedCityStatus: action.payload,
            };

        case "UPDATE_CITY_STATUS_FULFILLED":
                return {
                    ...state,
                    loadingCity: false,
                    changedCityStatus: action.payload,
                };

        case "GET_CITYLISTBYID_FULFILLED":
            return {
                ...state,
                loadingCity: false,
                cityLists: action.payload
            };

        case "DELETE_CITY_FULFILLED":
            return {
                ...state,
                loadingCity: false,
                changedCityStatus: action.payload
            };

        case "RESET_DELETE_CITY_STATUS":
            return {
                ...state,
                loadingCity: false,
                changedCityStatus: [],
                errorsCity:{}
            };

        case "GET_CITYLIST_REJECTED":
        case "ADD_CITY_REJECTED":
        case "UPDATE_CITY_REJECTED":
        case "UPDATE_CITY_STATUS_REJECTED":
        case "DELETE_CITY_REJECTED":
        case "GET_CITYLISTBYID_REJECTED":
            return {
                ...state,
                loadingCity: false,
                errorsCity: action.payload
            };

        default:
            return state;
    }
}

export default cityReducer;