const initialLocality = {
    localityPermissions:{},
    localityLists: [],
    localityStatus: [],
    changedLocalityStatus : [],
    loadingLocality: false,
    errorsLocality: {}
}

const  localityReducer = (locality = initialLocality, action) => {
    switch (action.type) {
        case "GET_LOCALITYLIST_PENDING":
        case "ADD_LOCALITY_PENDING":
        case "UPDATE_LOCALITY_PENDING":
        case "UPDATE_LOCALITY_STATUS_PENDING":
        case "DELETE_LOCALITY_PENDING":
        case "GET_LOCALITYLISTBYID_PENDING":
            return { ...locality, loadingLocality: true };

        case "GET_LOCALITYLIST_FULFILLED":
            return {
                ...locality,
                loadingLocality: false,
                localityLists: action.payload,
                localityCount: action.count,
                localityPermissions:action.permissions,
            };

        case "ADD_LOCALITY_FULFILLED":
            return {
                ...locality,
                loadingLocality: false,
                changedLocalityStatus: action.payload,
            };

        case "UPDATE_LOCALITY_FULFILLED":
            return {
                ...locality,
                loadingLocality: false,
                changedLocalityStatus: action.payload,
            };

        case "UPDATE_LOCALITY_STATUS_FULFILLED":
            return {
                ...locality,
                loadingLocality: false,
                changedLocalityStatus: action.payload,
            };

        case "GET_LOCALITYLISTBYID_FULFILLED":
            return {
                ...locality,
                loadingLocality: false,
                localityLists: action.payload
            };

        case "DELETE_LOCALITY_FULFILLED":
            return {
                ...locality,
                loadingLocality: false,
                changedLocalityStatus: action.payload
            };

        case "RESET_DELETE_LOCALITY_STATUS":
            return {
                ...locality,
                loadingLocality: false,
                localityStatus:{},
                changedLocalityStatus: [],
                errorsLocality:{}
            };

        case "GET_LOCALITYLIST_REJECTED":
        case "ADD_LOCALITY_REJECTED":
        case "UPDATE_LOCALITY_REJECTED":
        case "UPDATE_LOCALITY_STATUS_REJECTED":
        case "DELETE_LOCALITY_REJECTED":
        case "GET_LOCALITYLISTBYID_REJECTED":
            return {
                ...locality,
                loadingLocality: false,
                errorsLocality: action.payload
            };
        default:
            return locality;
    }
}

export default localityReducer;