import apiClient from "../../axios/apiClient";


export const getEducationList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_EDUCATIONLIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Education`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_EDUCATIONLIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_EDUCATIONLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveEducationData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_EDUCATION_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/Add-Education`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_EDUCATION_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_EDUCATION_REJECTED", payload: error.response });
    }
  };
  

  export const getEducationListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_EDUCATIONLISTBYID_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Education/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_EDUCATIONLISTBYID_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_EDUCATIONLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateEducationData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_EDUCATION_PENDING" });

    const response = await apiClient.post(
      `/api/admin/v1/Update-Education/${id}`, // Endpoint URL with vehicle ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_EDUCATION_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_EDUCATION_REJECTED", payload: error.response });
  }
};


export const updateEducationStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_EDUCATION_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await apiClient.post(
      `/api/admin/v1/Update-Education-Status/${id}`, // Endpoint URL with vehicle ID
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_EDUCATION_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_EDUCATION_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteEducationData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_EDUCATION_PENDING" });

    const response = await apiClient.delete(
      `/api/admin/v1/Delete-Education/${id}`, // Endpoint URL with vehicle ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_EDUCATION_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_EDUCATION_REJECTED", payload: error.response });
  }
};