const initialJob = {
    jobPermissions:{},
    jobLists: [],
    jobStatus: [],
    changedJobStatus : [],
    loadingJob: false,
    errorsJob: {}
}

const  jobReducer = (job = initialJob, action) => {
    switch (action.type) {
        case "GET_JOBLIST_PENDING":
        case "ADD_JOB_PENDING":
        case "UPDATE_JOB_PENDING":
        case "UPDATE_JOB_STATUS_PENDING":
        case "DELETE_JOB_PENDING":
        case "GET_JOBLISTBYID_PENDING":
            return { ...job, loadingJob: true };

        case "GET_JOBLIST_FULFILLED":
            return {
                ...job,
                loadingJob: false,
                jobLists: action.payload,
                jobCount: action.count,
                jobPermissions:action.permissions,
            };

        case "ADD_JOB_FULFILLED":
            return {
                ...job,
                loadingJob: false,
                changedJobStatus: action.payload,
            };

        case "UPDATE_JOB_FULFILLED":
            return {
                ...job,
                loadingJob: false,
                changedJobStatus: action.payload,
            };

        case "UPDATE_JOB_STATUS_FULFILLED":
            return {
                ...job,
                loadingJob: false,
                changedJobStatus: action.payload,
            };

        case "GET_JOBLISTBYID_FULFILLED":
            return {
                ...job,
                loadingJob: false,
                jobLists: action.payload
            };

        case "DELETE_JOB_FULFILLED":
            return {
                ...job,
                loadingJob: false,
                changedJobStatus: action.payload
            };

        case "RESET_DELETE_JOB_STATUS":
            return {
                ...job,
                loadingJob: false,
                jobStatus:{},
                changedJobStatus: [],
                errorsJob:{}
            };

        case "GET_JOBLIST_REJECTED":
        case "ADD_JOB_REJECTED":
        case "UPDATE_JOB_REJECTED":
        case "UPDATE_JOB_STATUS_REJECTED":
        case "DELETE_JOB_REJECTED":
        case "GET_JOBLISTBYID_REJECTED":
            return {
                ...job,
                loadingJob: false,
                errorsJob: action.payload
            };
        default:
            return job;
    }
}

export default jobReducer;