import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form, Table, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Preloader from "../../Components/Preloader";
import { getLiveTracking } from "../../redux/actions/MapActions";
import LeafletMapAllVehicle from "../../Components/LeafletMapAllVehicle";
import GoogleMapAllVehicle from "../../Components/GoogleMapAllVehicle";
import Select from "react-dropdown-select";
import { getMasterUsertypeUserWiseList, getMasterUserUserTypeWiseList } from "../../redux/actions/MasterActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import FeatherIcon from "feather-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery20Icon from "@mui/icons-material/Battery20";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery80Icon from "@mui/icons-material/Battery80";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";


const LiveTrack = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const [formData, setFormData] = useState({
    lat:0.00,
    lng:0.00
  });
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    mapReducer:{
      liveTrackingData,
      vehicleCount,
      liveTrackingPermissionData,
      loadingMap,
      errorsMap,
    },
  } = useSelector((state) => state);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  
  // useEffect(() => {
  //   // Function to fetch live tracking data
  //   const fetchLiveTracking = () => {
  //     dispatch(getLiveTracking(auth.token,1000,1,"","ASC"));
  //   };
  
  //   // Initial fetch
  //   fetchLiveTracking();
  
  //   // Set an interval to fetch data every 30 seconds
  //   const intervalId = setInterval(() => {
  //     // fetchLiveTracking();
  //   }, 30000); // 30,000 milliseconds = 30 seconds
  
  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [auth, dispatch]);

  const fetchMapList = useCallback(() => {
    if (auth?.token) {
      dispatch(getLiveTracking(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchMapList, 500, [auth, paginate, page, search, sort]);
  

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(()=>{
    console.log('liveTrackingData',liveTrackingData);
  },[liveTrackingData])

  useEffect(() => {
      document.title = `Live Tracking :: ${auth?.generalSettings?.title}`;
    }, []);


  const getBatteryIcon = (batteryLevel) => {
    if (batteryLevel === 100) return `faBatteryFull`;
    if (batteryLevel >= 75) return `faBatteryThreeQuarters`;
    if (batteryLevel >= 50) return `faBatteryHalf`;
    if (batteryLevel >= 25) return `faBatteryQuarter`;
    return `faBatteryEmpty`;
  };

  const getBatteryColor = (batteryLevel) => {
    if (batteryLevel >= 75) return "green";
    if (batteryLevel >= 25) return "orange";
    return "red";
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Live Tracking"), path: "/Live-Tracking", active: true },
        ]}
        title={t("Live Tracking")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                    {(liveTrackingPermissionData.filter ) && (<>
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                      </>)}
                      
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ position: "relative", overflow: "hidden", height: "600px" }}>
                <GoogleMapAllVehicle
                  mapHeight={"600px"}
                  vehicleData={liveTrackingData}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
            <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  size="sm"
                  striped
                  responsive
                  >
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>User Type</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>Status</th>
                      <th>Registered At</th>
                    </tr>
                  </thead>
                  <tbody>
                  {liveTrackingData.length === 0 ? (
                    <tr>
                      <td colSpan="12">No data found</td>
                    </tr>
                  ) : (<>
                    {liveTrackingData?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.name}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.adminUserType?.name}</td>
                          <td>{item?.address}</td>
                          <td>{item?.citiesAdminUsers?.city_name}</td>
                          <td>
                            {item?.activity_type === "still" ? 
                              <FeatherIcon icon="activity" className="icon-dual" />
                            : <>
                                <Badge className={"bg-success"}>{item?.activity_type}</Badge>
                              </>}
                            {item?.is_charging === true ? 
                              <FeatherIcon icon="battery-charging" className="icon-dual" />
                            : <>
                            <FeatherIcon icon="battery" className="icon-dual" />
                              </>
                            }
                            <FontAwesomeIcon
                              icon={item?.is_charging === true ? `faChargingStation` : getBatteryIcon(parseInt(item?.level))}
                              style={{ fontSize: "48px", color: getBatteryColor(parseInt(item?.level)) }}
                            />
                          </td>
                          <td>{getDisplayDateTimeFormat(item?.lastLocation)}</td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMap && (<Preloader/>)}
    </>
  );
};

export default LiveTrack;