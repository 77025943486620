
import apiClient from "../../axios/apiClient";
import { getDateTimeMysqlFormatyymm, getDateTimeMysqlFormatyymmdd } from "../../util/generalUtills";

export const getAttendanceMonthlyList =
  (token, limit = 10, page = 1, search = "", sort = "desc",month) =>
  async (dispatch) => {
    try {
      const m=getDateTimeMysqlFormatyymm(month)
      console.log('month',month,m);
      dispatch({ type: "GET_ATTENDANCE_MONTHLY_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Attendance/Monthly?`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            month:m
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_ATTENDANCE_MONTHLY_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_ATTENDANCE_MONTHLY_REJECTED",
        payload: error.response,
      });
    }
  };


  export const getAttendanceDailyList =
  (token, limit = 10, page = 1, search = "", sort = "desc",month) =>
  async (dispatch) => {
    try {
      const m=getDateTimeMysqlFormatyymmdd(month)
      console.log('month',month,m);
      dispatch({ type: "GET_ATTENDANCE_DAILY_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Attendance/Daily?`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            attendanceDate:m
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_ATTENDANCE_DAILY_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_ATTENDANCE_DAILY_REJECTED",
        payload: error.response,
      });
    }
  };

  export const getAttendanceMonthlyDetailsList =
  (token, limit = 10, page = 1, search = "", sort = "desc",employeeId,month) =>
  async (dispatch) => {
    try {
      const m=getDateTimeMysqlFormatyymm(month)
      console.log('month',month,m);
      dispatch({ type: "GET_ATTENDANCE_MONTHLY_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Attendance/Monthly-Details?`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            month:m,
            employeeId:employeeId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_ATTENDANCE_MONTHLY_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_ATTENDANCE_MONTHLY_REJECTED",
        payload: error.response,
      });
    }
  };


  export const checkInServerAction =
  (token, formData) =>
  async (dispatch) => {
    try {
      console.log('data',formData,token)
      dispatch({ type: "ATTENDANCE_CHECK_IN_ACTION_PENDING" });
      const response = await apiClient.post(
        `api/admin/V1/Attendance-CheckIn`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response',response);
      dispatch({ type: "ATTENDANCE_CHECK_IN_ACTION_FULFILLED", payload: response.data });
    } catch (error) {
        console.log('error', error);
        dispatch({ type: "ATTENDANCE_CHECK_IN_ACTION_REJECTED", payload: error.message });
    }
  };