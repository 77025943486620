import apiClient from "../../axios/apiClient";


  export const getUserTypePermissionListByUserTypeId =(token,id) =>
  async (dispatch) => {
    try {
      if(id==null)
      {
        return;
      }
      dispatch({ type: "GET_USERTYPE_PERMISSION_LISTBY_USERTYPE_ID_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Usertype-Permission/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_USERTYPE_PERMISSION_LISTBY_USERTYPE_ID_FULFILLED",
        payload: response.data?.data,
        usertypeCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_USERTYPE_PERMISSION_LISTBY_USERTYPE_ID_REJECTED",
        payload: error.response,
      });
    }
  };

export const updateUserTypePermissionData = (token, id,permissionType,parentId,menuId,userTypeId) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_USERTYPE_PERMISSION__PENDING" });
    const data={
      id:id,
      permissionType:permissionType,
      parentId:parentId,
      menuId:menuId,
      userTypeId:userTypeId,
    }
    const response = await apiClient.post(
      `/api/admin/v1/Update-Usertype-Permission`, // Endpoint URL with City ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_USERTYPE_PERMISSION__FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_USERTYPE_PERMISSION__REJECTED", payload: error.response });
  }
};
