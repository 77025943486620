const initialEducation = {
    educationPermissions:{},
    educationLists: [],
    educationStatus: [],
    changedEducationStatus : [],
    loadingEducation: false,
    errorsEducation: {}
}

const  educationReducer = (education = initialEducation, action) => {
    switch (action.type) {
        case "GET_EDUCATIONLIST_PENDING":
        case "ADD_EDUCATION_PENDING":
        case "UPDATE_EDUCATION_PENDING":
        case "UPDATE_EDUCATION_STATUS_PENDING":
        case "DELETE_EDUCATION_PENDING":
        case "GET_EDUCATIONLISTBYID_PENDING":
            return { ...education, loadingEducation: true };

        case "GET_EDUCATIONLIST_FULFILLED":
            return {
                ...education,
                loadingEducation: false,
                educationLists: action.payload,
                educationCount: action.count,
                educationPermissions:action.permissions,
            };

        case "ADD_EDUCATION_FULFILLED":
            return {
                ...education,
                loadingEducation: false,
                changedEducationStatus: action.payload,
            };

        case "UPDATE_EDUCATION_FULFILLED":
            return {
                ...education,
                loadingEducation: false,
                changedEducationStatus: action.payload,
            };

        case "UPDATE_EDUCATION_STATUS_FULFILLED":
            return {
                ...education,
                loadingEducation: false,
                changedEducationStatus: action.payload,
            };

        case "GET_EDUCATIONLISTBYID_FULFILLED":
            return {
                ...education,
                loadingEducation: false,
                educationLists: action.payload
            };

        case "DELETE_EDUCATION_FULFILLED":
            return {
                ...education,
                loadingEducation: false,
                changedEducationStatus: action.payload
            };

        case "RESET_DELETE_EDUCATION_STATUS":
            return {
                ...education,
                loadingEducation: false,
                educationStatus:{},
                changedEducationStatus: [],
                errorsEducation:{}
            };

        case "GET_EDUCATIONLIST_REJECTED":
        case "ADD_EDUCATION_REJECTED":
        case "UPDATE_EDUCATION_REJECTED":
        case "UPDATE_EDUCATION_STATUS_REJECTED":
        case "DELETE_EDUCATION_REJECTED":
        case "GET_EDUCATIONLISTBYID_REJECTED":
            return {
                ...education,
                loadingEducation: false,
                errorsEducation: action.payload
            };
        default:
            return education;
    }
}

export default educationReducer;