import React, { useEffect, useRef, useState } from 'react';
import { AdvancedMarker, APIProvider, Map, InfoWindow } from '@vis.gl/react-google-maps';
import { getDisplayDateTimeFormat } from '../util/generalUtills';
import '../css/GoogleMapAllVehicle.css';
import { apiURL } from '../axios';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const GoogleMapAllVehicle = ({ mapHeight, vehicleData }) => {
  const mapRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState(null); // State for user's location
  const [hoveredVehicle, setHoveredVehicle] = useState(null); // Track hovered vehicle
  const [selectedVehicle, setSelectedVehicle] = useState(null); // Track clicked vehicle

  const onLoad = (map) => {
    mapRef.current = map;

    // Only set the user's location after the map has loaded
    if (navigator.geolocation && !currentLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    }

    if (vehicleData.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      // Add markers to the bounds
      vehicleData.forEach((vehicle) => {
        const lat = parseFloat(vehicle.lat);
        const lng = parseFloat(vehicle.lng);
        if (lat > 0 && lng > 0) {
          const markerPosition = new window.google.maps.LatLng(lat, lng);
          bounds.extend(markerPosition); // Add each marker's position to the bounds
        }
      });

      // After all markers are added, fit the map to the bounds
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds); // Fit the map to show all vehicle markers
      }
    }
  };

  const handleMarkerClick = (vehicle) => {
    setSelectedVehicle(vehicle); // Set the clicked vehicle to show the InfoWindow
  };

  
  return (
    <div style={{ height: mapHeight }}>
      <APIProvider
        solutionChannel="GMP_devsite_samples_v3_rgmbasicmap"
        apiKey={`AIzaSyCzMU4VXRP_RFXHNKi15YlGAMAbflJ9dfU`}
      >
        <Map
          defaultZoom={15}
          // zoom={15}
          // defaultCenter={currentLocation}
          // center={currentLocation}
          gestureHandling={"greedy"}
          disableDefaultUI={false}
          onLoad={onLoad}
          mapId="brain_bird_map_id" // Add your Map ID here
        >
          {vehicleData.map((vehicle, index) => (
            parseFloat(vehicle.lat) > 0 && parseFloat(vehicle.lng) > 0 && (
              <AdvancedMarker
                key={index}
                position={{ lat: parseFloat(vehicle.lat), lng: parseFloat(vehicle.lng) }}
                onClick={() => handleMarkerClick(vehicle)} // Open InfoWindow on marker click
              >
                <img src={`${apiURL}user.png`} width={32} height={32} />
                {selectedVehicle && selectedVehicle.id === vehicle.id && (
                  <InfoWindow
                    position={{ lat: parseFloat(vehicle.lat), lng: parseFloat(vehicle.lng) }}
                    onCloseClick={() => setSelectedVehicle(null)} // Close InfoWindow
                  >
                    <div className="info-window">
                      <div className="info-window-header">
                        <h3>{vehicle?.name} ({vehicle?.adminUserType?.name})</h3>
                        <div className="status-icon">
                          {vehicle?.status === 'Running' ? (
                            <span className="status running">🚗</span>
                          ) : (
                            <span className="status stopped">🛑</span>
                          )}
                        </div>
                      </div>
                      <div className="info-window-content">
                        <p><strong>Contact:</strong> {vehicle?.phone}</p>
                        <p><strong>Status:</strong> {vehicle?.status}</p>
                        <p><strong>Speed:</strong> {vehicle?.speed} km/h</p>
                        <p><strong>Accuracy:</strong> {vehicle?.accuracy} m</p>
                        <p><strong>Last Location:</strong> {getDisplayDateTimeFormat(vehicle?.lastLocation)}</p>
                        <p><strong>Address:</strong> {vehicle?.address}</p>
                        <p><strong>City:</strong> {vehicle?.citiesAdminUsers?.city_name}</p>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </AdvancedMarker>
            )
          ))}
        </Map>
      </APIProvider>
    </div>
  );
};

export default GoogleMapAllVehicle;
