const initialOccupation = {
    occupationPermissions:{},
    occupationLists: [],
    occupationStatus: [],
    changedOccupationStatus : [],
    loadingOccupation: false,
    errorsOccupation: {}
}

const  occupationReducer = (occupation = initialOccupation, action) => {
    switch (action.type) {
        case "GET_OCCUPATIONLIST_PENDING":
        case "ADD_OCCUPATION_PENDING":
        case "UPDATE_OCCUPATION_PENDING":
        case "UPDATE_OCCUPATION_STATUS_PENDING":
        case "DELETE_OCCUPATION_PENDING":
        case "GET_OCCUPATIONLISTBYID_PENDING":
            return { ...occupation, loadingOccupation: true };

        case "GET_OCCUPATIONLIST_FULFILLED":
            return {
                ...occupation,
                loadingOccupation: false,
                occupationLists: action.payload,
                occupationCount: action.count,
                occupationPermissions:action.permissions,
            };

        case "ADD_OCCUPATION_FULFILLED":
            return {
                ...occupation,
                loadingOccupation: false,
                changedOccupationStatus: action.payload,
            };

        case "UPDATE_OCCUPATION_FULFILLED":
            return {
                ...occupation,
                loadingOccupation: false,
                changedOccupationStatus: action.payload,
            };

        case "UPDATE_OCCUPATION_STATUS_FULFILLED":
            return {
                ...occupation,
                loadingOccupation: false,
                changedOccupationStatus: action.payload,
            };

        case "GET_OCCUPATIONLISTBYID_FULFILLED":
            return {
                ...occupation,
                loadingOccupation: false,
                occupationLists: action.payload
            };

        case "DELETE_OCCUPATION_FULFILLED":
            return {
                ...occupation,
                loadingOccupation: false,
                changedOccupationStatus: action.payload
            };

        case "RESET_DELETE_OCCUPATION_STATUS":
            return {
                ...occupation,
                loadingOccupation: false,
                occupationStatus:{},
                changedOccupationStatus: [],
                errorsOccupation:{}
            };

        case "GET_OCCUPATIONLIST_REJECTED":
        case "ADD_OCCUPATION_REJECTED":
        case "UPDATE_OCCUPATION_REJECTED":
        case "UPDATE_OCCUPATION_STATUS_REJECTED":
        case "DELETE_OCCUPATION_REJECTED":
        case "GET_OCCUPATIONLISTBYID_REJECTED":
            return {
                ...occupation,
                loadingOccupation: false,
                errorsOccupation: action.payload
            };
        default:
            return occupation;
    }
}

export default occupationReducer;