const initialState = {
    usersPermissions:[],
    userLists: [],
    userSingleList: [],
    userStatus: [],
    changedUserStatus : [],
    loadingUser: false,
    errorsUser: {}
}

const  userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_USERLIST_PENDING":
        case "ADD_USER_PENDING":
        case "UPDATE_USER_PENDING":
        case "DELETE_USER_PENDING":
        case "RESEND_EMAIL_CONFIRMATION_PENDING":
        case "UPDATE_USER_STATUS_PENDING":
        case "LOGOUT_USER_DEVICE_PENDING":
        case "GET_USERLISTBYID_PENDING":
            return { ...state, loadingUser: true };

        case "GET_USERLIST_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                userLists: action.payload,
                userCount: action.userCount,
                usersPermissions:action.permissions,
            };

        case "ADD_USER_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                changedUserStatus: action.payload,
            };

        case "UPDATE_USER_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                changedUserStatus: action.payload,
            };
        
        case "UPDATE_USER_STATUS_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                changedUserStatus: action.payload,
            };

        case "RESEND_EMAIL_CONFIRMATION_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                changedUserStatus: action.payload,
            };

        case "LOGOUT_USER_DEVICE_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                changedUserStatus: action.payload,
            };

        case "GET_USERLISTBYID_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                userSingleList: action.payload
            };

        case "DELETE_USER_FULFILLED":
            return {
                ...state,
                loadingUser: false,
                changedUserStatus: action.payload
            };

        case "RESET_DELETE_USER_STATUS":
            return {
                ...state,
                loadingUser: false,
                userSingleList:[],
                changedUserStatus: [],
                errorsUser:{}
            };

        case "GET_USERLIST_REJECTED":
        case "ADD_USER_REJECTED":
        case "UPDATE_USER_REJECTED":
        case "DELETE_USER_REJECTED":
        case "RESEND_EMAIL_CONFIRMATION_REJECTED":
        case "GET_USERLISTBYID_REJECTED":
        case "LOGOUT_USER_DEVICE_REJECTED":
        case "UPDATE_USER_STATUS_REJECTED" :
            return {
                ...state,
                loadingUser: false,
                errorsUser: action.payload
            };

        default:
            return state;
    }
}

export default userReducer;