import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios'; // Import Axios for HTTP requests
import L from 'leaflet';


const LeafletSingleMap = ({ mapHeight, center, zoom, setFormData }) => {
  const customSourceIcon = new L.Icon({
    iconUrl: 'https://phedrserver.asginnovations.in/marker-blue.png',
    iconSize: [25, 41], // Size of the icon
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
    shadowUrl: 'leaflet/dist/images/marker-shadow.png',
    shadowSize: [41, 41], // Size of the shadow
  });

  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState(center); // Initialize position with center prop
  const markerRef = useRef(null);

  // Update position state when center prop changes
  useEffect(() => {
    setPosition(center);
  }, [center]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const newPosition = marker.getLatLng();
          setPosition([newPosition.lat, newPosition.lng]);
          setFormData(prevData => ({
            ...prevData,
            lat: parseFloat(newPosition.lat).toFixed(8),
            lng: parseFloat(newPosition.lng).toFixed(8),
          }));
          getAddressFromCoords(parseFloat(newPosition.lat).toFixed(8),parseFloat(newPosition.lng).toFixed(8));
        }
      },
    }),
    [setFormData]
  );

  const getAddressFromCoords = async (lat, lng) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`);
      setFormData(prevData => ({
        ...prevData,
        address: response.data.display_name,
      }));
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const MapUpdater = () => {
    const map = useMap();
    useEffect(() => {
      map.setView(position, zoom); // Set initial view with position and zoom
    }, [position, zoom, map]);
    return null;
  };

  return (
    <div style={{ height: mapHeight }}>
      <MapContainer center={center} zoom={zoom} style={{ height: '100%' }}>
        <MapUpdater />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          draggable={draggable}
          eventHandlers={eventHandlers}
          position={position}
          ref={markerRef}
          icon={customSourceIcon}
        />
      </MapContainer>
    </div>
  );
};

export default LeafletSingleMap;
