import apiClient from "../../axios/apiClient";


export const getLiveTracking =
  (token, limit = 1000, page = 1, search = "", sort = "ASC") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_LIVE_TRACKING_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Map/Live-Tracking`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_LIVE_TRACKING_FULFILLED",
        payload: response.data?.data,
        permissions:response?.data?.permissions,
        vehicleCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_LIVE_TRACKING_REJECTED",
        payload: error.response,
      });
    }
  };


  export const getHistoryTracking =
  (token,bookingId,date,startTime,endTime,vehicleId,driverId,deviceId) =>
  // (token,bookingId="47", date = "2024-10-22", startTime = "00:00:00", endTime = "20:00:00",vehicleId="2", driverId = "2",deviceId="") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_HISTORY_TRACKING_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Map/History-Tracking`,
        {
          params: {
            bookingId: bookingId,
            date: date,
            startTime: startTime,
            endTime: endTime,
            vehicleId:vehicleId,
            driverId:driverId,
            deviceId:deviceId

          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_HISTORY_TRACKING_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_HISTORY_TRACKING_REJECTED",
        payload: error.response,
      });
    }
  };
