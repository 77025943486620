import apiClient from "../../axios/apiClient";



export const getLeaveList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_LEAVELIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Leave`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_LEAVELIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_LEAVELIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveLeaveData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_LEAVE_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/Add-Leave`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_LEAVE_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_LEAVE_REJECTED", payload: error.response });
    }
  };
  

  export const getLeaveListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_LEAVELISTBYID_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Leave/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_LEAVELISTBYID_FULFILLED",
        payload: response.data?.data,
        cityCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_LEAVELISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateLeaveData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_LEAVE_PENDING" });

    const response = await apiClient.post(
      `/api/admin/v1/Update-Leave/${id}`, // Endpoint URL with Leave ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_LEAVE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_LEAVE_REJECTED", payload: error.response });
  }
};


export const updateLeaveStatusData = (token, id, status,remark) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_LEAVE_STATUS_PENDING" });
    const data={
      "status":status,
      "approve_remark":remark
    }
    const response = await apiClient.post(
      `/api/admin/v1/Update-Leave-Status/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_LEAVE_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_LEAVE_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteLeaveData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_LEAVE_PENDING" });

    const response = await apiClient.delete(
      `/api/admin/v1/Delete-Leave/${id}`, // Endpoint URL with Leave ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_LEAVE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_LEAVE_REJECTED", payload: error.response });
  }
};