const initialTypeOfClaim = {
    typeOfClaimPermissions:{},
    typeOfClaimLists: [],
    typeOfClaimStatus: [],
    changedTypeOfClaimStatus : [],
    loadingTypeOfClaim: false,
    errorsTypeOfClaim: {}
}

const  typeOfClaimReducer = (typeOfClaim = initialTypeOfClaim, action) => {
    switch (action.type) {
        case "GET_TYPE_OF_CLAIMLIST_PENDING":
        case "ADD_TYPE_OF_CLAIM_PENDING":
        case "UPDATE_TYPE_OF_CLAIM_PENDING":
        case "UPDATE_TYPE_OF_CLAIM_STATUS_PENDING":
        case "DELETE_TYPE_OF_CLAIM_PENDING":
        case "GET_TYPE_OF_CLAIMLISTBYID_PENDING":
            return { ...typeOfClaim, loadingTypeOfClaim: true };

        case "GET_TYPE_OF_CLAIMLIST_FULFILLED":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                typeOfClaimLists: action.payload,
                typeOfClaimCount: action.count,
                typeOfClaimPermissions:action.permissions,
            };

        case "ADD_TYPE_OF_CLAIM_FULFILLED":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                changedTypeOfClaimStatus: action.payload,
            };

        case "UPDATE_TYPE_OF_CLAIM_FULFILLED":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                changedTypeOfClaimStatus: action.payload,
            };

        case "UPDATE_TYPE_OF_CLAIM_STATUS_FULFILLED":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                changedTypeOfClaimStatus: action.payload,
            };

        case "GET_TYPE_OF_CLAIMLISTBYID_FULFILLED":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                typeOfClaimLists: action.payload
            };

        case "DELETE_TYPE_OF_CLAIM_FULFILLED":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                changedTypeOfClaimStatus: action.payload
            };

        case "RESET_DELETE_TYPE_OF_CLAIM_STATUS":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                typeOfClaimStatus:{},
                changedTypeOfClaimStatus: [],
                errorsTypeOfClaim:{}
            };

        case "GET_TYPE_OF_CLAIMLIST_REJECTED":
        case "ADD_TYPE_OF_CLAIM_REJECTED":
        case "UPDATE_TYPE_OF_CLAIM_REJECTED":
        case "UPDATE_TYPE_OF_CLAIM_STATUS_REJECTED":
        case "DELETE_TYPE_OF_CLAIM_REJECTED":
        case "GET_TYPE_OF_CLAIMLISTBYID_REJECTED":
            return {
                ...typeOfClaim,
                loadingTypeOfClaim: false,
                errorsTypeOfClaim: action.payload
            };
        default:
            return typeOfClaim;
    }
}

export default typeOfClaimReducer;