import axios from "axios";
import { baseURL } from ".";

// Create a custom Axios instance
const apiClient = axios.create({
  baseURL: baseURL,
  timeout: 30000, // Optional: set a timeout for requests
});

// Add Response Interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Handle unauthorized access globally
      localStorage.removeItem("brainbird_insurance_admin");
      window.location.href = "/login"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default apiClient;
