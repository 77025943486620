const initialLeave = {
    leavePermissions:{},
    leaveLists: [],
    leaveStatus: [],
    changedLeaveStatus : [],
    loadingLeave: false,
    errorsLeave: {}
}

const  leaveReducer = (state = initialLeave, action) => {
    switch (action.type) {
        case "GET_LEAVELIST_PENDING":
        case "ADD_LEAVE_PENDING":
        case "UPDATE_LEAVE_PENDING":
        case "UPDATE_LEAVE_STATUS_PENDING":
        case "DELETE_LEAVE_PENDING":
        case "GET_LEAVELISTBYID_PENDING":
            return { ...state, loadingLeave: true };

        case "GET_LEAVELIST_FULFILLED":
            return {
                ...state,
                loadingLeave: false,
                leaveLists: action.payload,
                leaveCount: action.count,
                leavePermissions:action.permissions,
            };

        case "ADD_LEAVE_FULFILLED":
            return {
                ...state,
                loadingLeave: false,
                changedLeaveStatus: action.payload,
            };

        case "UPDATE_LEAVE_FULFILLED":
            return {
                ...state,
                loadingLeave: false,
                changedLeaveStatus: action.payload,
            };

        case "UPDATE_LEAVE_STATUS_FULFILLED":
                return {
                    ...state,
                    loadingLeave: false,
                    changedLeaveStatus: action.payload,
                };

        case "GET_LEAVELISTBYID_FULFILLED":
            return {
                ...state,
                loadingLeave: false,
                leaveLists: action.payload
            };

        case "DELETE_LEAVE_FULFILLED":
            return {
                ...state,
                loadingLeave: false,
                changedLeaveStatus: action.payload
            };

        case "RESET_DELETE_LEAVE_STATUS":
            return {
                ...state,
                loadingLeave: false,
                changedLeaveStatus: [],
                errorsLeave:{}
            };

        case "GET_LEAVELIST_REJECTED":
        case "ADD_LEAVE_REJECTED":
        case "UPDATE_LEAVE_REJECTED":
        case "UPDATE_LEAVE_STATUS_REJECTED":
        case "DELETE_LEAVE_REJECTED":
        case "GET_LEAVELISTBYID_REJECTED":
            return {
                ...state,
                loadingLeave: false,
                errorsLeave: action.payload
            };

        default:
            return state;
    }
}

export default leaveReducer;