const initialCaseDetails = {
    caseDetailsPermissions:{},
    caseDetailsLists: [],
    caseDetailsStatus:[],
    changedCaseDetailsStatus : [],
    loadingCaseDetails: false,
    errorsCaseDetails: {}
}

const  caseDetailsReducer = (caseDetails = initialCaseDetails, action) => {
    switch (action.type) {
        case "GET_CASE_DETAILS_LIST_PENDING":
        case "ADD_CASE_INITIATED_PENDING":
        case "ADD_CASE_GENERATED_PENDING":
            return { ...caseDetails, loadingCaseDetails: true };

        case "GET_CASE_DETAILS_LIST_FULFILLED":
            return {
                ...caseDetails,
                loadingCaseDetails: false,
                caseDetailsLists: action.payload,
                caseDetailsCount: action.count,
                caseDetailsPermissions:action.permissions,
            };

        case "ADD_CASE_INITIATED_FULFILLED":
            return {
                ...caseDetails,
                loadingCaseDetails: false,
                changedCaseDetailsStatus: action.payload,
            };

        case "ADD_CASE_GENERATED_FULFILLED":
            return {
                ...caseDetails,
                loadingCaseDetails: false,
                changedCaseDetailsStatus: action.payload,
            };

        case "GET_CASE_DETAILS_LISTBYID_FULFILLED":
            return {
                ...caseDetails,
                loadingCaseDetails: false,
                caseDetailsLists: action.payload
            };

        case "RESET_DELETE_CASE_DETAILS_STATUS":
            return {
                ...caseDetails,
                loadingCaseDetails: false,
                caseDetailsStatus:{},
                changedCaseDetailsStatus: [],
                errorsCaseDetails:{}
            };

        case "GET_CASE_DETAILS_LIST_REJECTED":
        case "ADD_CASE_INITIATED_REJECTED":
        case "ADD_CASE_GENERATED_REJECTED":
        case "GET_CASE_DETAILS_LISTBYID_REJECTED":
            return {
                ...caseDetails,
                loadingCaseDetails: false,
                errorsCaseDetails: action.payload
            };
        default:
            return caseDetails;
    }
}

export default caseDetailsReducer;