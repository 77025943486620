const initialChecklist = {
    checklistPermissions:{},
    checklistLists: [],
    checklistStatus: [],
    changedChecklistStatus : [],
    loadingChecklist: false,
    errorsChecklist: {}
}

const  checklistReducer = (checklist = initialChecklist, action) => {
    switch (action.type) {
        case "GET_CHECKLIST_LIST_PENDING":
        case "ADD_CHECKLIST_PENDING":
        case "UPDATE_CHECKLIST_PENDING":
        case "UPDATE_CHECKLIST_STATUS_PENDING":
        case "DELETE_CHECKLIST_PENDING":
        case "GET_CHECKLIST_LISTBYID_PENDING":
            return { ...checklist, loadingChecklist: true };

        case "GET_CHECKLIST_LIST_FULFILLED":
            return {
                ...checklist,
                loadingChecklist: false,
                checklistLists: action.payload,
                checklistCount: action.count,
                checklistPermissions:action.permissions,
            };

        case "ADD_CHECKLIST_FULFILLED":
            return {
                ...checklist,
                loadingChecklist: false,
                changedChecklistStatus: action.payload,
            };

        case "UPDATE_CHECKLIST_FULFILLED":
            return {
                ...checklist,
                loadingChecklist: false,
                changedChecklistStatus: action.payload,
            };

        case "UPDATE_CHECKLIST_STATUS_FULFILLED":
            return {
                ...checklist,
                loadingChecklist: false,
                changedChecklistStatus: action.payload,
            };

        case "GET_CHECKLIST_LISTBYID_FULFILLED":
            return {
                ...checklist,
                loadingChecklist: false,
                checklistLists: action.payload
            };

        case "DELETE_CHECKLIST_FULFILLED":
            return {
                ...checklist,
                loadingChecklist: false,
                changedChecklistStatus: action.payload
            };

        case "RESET_DELETE_CHECKLIST_STATUS":
            return {
                ...checklist,
                loadingChecklist: false,
                checklistStatus:{},
                changedChecklistStatus: [],
                errorsChecklist:{}
            };

        case "GET_CHECKLIST_LIST_REJECTED":
        case "ADD_CHECKLIST_REJECTED":
        case "UPDATE_CHECKLIST_REJECTED":
        case "UPDATE_CHECKLIST_STATUS_REJECTED":
        case "DELETE_CHECKLIST_REJECTED":
        case "GET_CHECKLIST_LISTBYID_REJECTED":
            return {
                ...checklist,
                loadingChecklist: false,
                errorsChecklist: action.payload
            };
        default:
            return checklist;
    }
}

export default checklistReducer;