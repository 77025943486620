import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveGeneralSettingData, getGeneralSettingList } from "../../redux/actions/GeneralSettingActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import {getDateTimeMysqlGivenFormatYmd } from '../../util/generalUtills';
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    generalSettingReducer: {
      generalSettingLists,
      generalSettingPermissions,
      loadingGeneralSetting,
      errorsGeneralSetting,
      changedGeneralSettingStatus,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getGeneralSettingList(auth?.token, id));
  }, [auth, id]);


  const options = [
    { value: 'Enable', label: 'Enable', isDisabled: false },
    { value: 'Disable', label: 'Disable', isDisabled: false },
  ];

  const options2 = [
    { value: 'SMS', label: 'SMS', isDisabled: false },
    { value: 'Whatsapp', label: 'Whatsapp', isDisabled: false },
  ];

  const options3 = [
    { value: 'Image', label: 'Image', isDisabled: false },
    { value: 'Video', label: 'Video', isDisabled: false },
  ];

  const options4 = [
    { value: 'Google', label: 'Google', isDisabled: false },
    { value: 'Mapbox', label: 'Mapbox', isDisabled: false },
  ];

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(saveGeneralSettingData(auth?.token, formData));
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsGeneralSetting && Object.keys(errorsGeneralSetting).length > 0)
    {
      if (errorsGeneralSetting?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsGeneralSetting?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
      }
      else if (errorsGeneralSetting?.status === 400)
      {
        // Handle other status
      }
      else
      {
        dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
      }
    }
  }, [errorsGeneralSetting, dispatch]);

  useEffect(() => {
    if(generalSettingLists && Object.keys(generalSettingLists).length>0)
    {
      setFormData(generalSettingLists);
    }
  }, [generalSettingLists]);

  useEffect(() => {
    if (changedGeneralSettingStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedGeneralSettingStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
      dispatch(getGeneralSettingList(auth?.token, id));
    }
  }, [changedGeneralSettingStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].value,
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  const onDateChange = (name,value) => {
    if (value) {
      setFormData({ ...formData, [name]: getDateTimeMysqlGivenFormatYmd(value) });
    }
  };

  useEffect(() => {
      document.title = `General Settings :: ${auth?.generalSettings?.title}`;
    }, []);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("General Setting"), path: "General Setting" },
        ]}
        title={t("General Setting")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              {errorsGeneralSetting?.data?.message && (
                <div className="alert alert-danger">
                  {errorsGeneralSetting?.data?.message}
                </div>
              )}

              <Row>
                <Col lg={3}>
                  <FormInput
                    label={t("Company Name")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.company_name}
                    name="company_name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Title")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.title}
                    name="title"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Web Url")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.url}
                    name="url"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("App Url")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.app_url}
                    name="app_url"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Map Api")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.map_api}
                    name="map_api"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Map Type")}</Form.Label>
                    <Select
                      options={options4}
                      values={options4.filter((opt) => opt.value === formData.map_type)}
                      name="map_type"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "map_type")}
                      styles={customSelectStyles}
                      disabled={!generalSettingPermissions?.edit}
                    />
                    
                    {errorsGeneralSetting?.data?.map_type && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.map_type}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Location Interval")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.location_interval}
                    name="location_interval"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                
                <Col lg={3}>
                  <FormInput
                    label={t("Field Officer Attendance Start Time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.field_office_start_time}
                    name="field_office_start_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Field Officer Attendance End Time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.field_office_end_time}
                    name="field_office_end_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Backed Officer Attendance Start Time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.back_office_start_time}
                    name="back_office_start_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Backed Officer Attendance End Time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.back_office_end_time}
                    name="back_office_end_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Other Officer Attendance Start Time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.other_office_start_time}
                    name="other_office_start_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Other Officer Attendance End Time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.other_office_end_time}
                    name="other_office_end_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                    readOnly={!generalSettingPermissions?.edit}
                  />
                </Col>

              </Row>
              {generalSettingPermissions?.edit && (<>
                <div className="d-flex justify-content-center">
                  {loading ? 
                    <Button color="primary" disabled>
                      <Spinner
                        className="spinner-border-sm me-1"
                        tag="span"
                        color="white"
                      />
                      Please wait ...
                    </Button>
                  :
                    <Button type="button" onClick={handleSubmit} disabled={loading}>
                      Submit
                    </Button>
                  }
                </div>
              </>)}
              
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingGeneralSetting && (<Preloader/>)}
    </>
  );
};

export default Dashboard;