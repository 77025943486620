import apiClient from "../../axios/apiClient";

export const getZoneList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_ZONELIST_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Zone`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_ZONELIST_FULFILLED",
        payload: response.data?.data,
        zoneCount: response.data?.count,
        permissions: response.data?.permissions,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_ZONELIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveZoneData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_ZONE_PENDING" });
  
      const response = await apiClient.post(
        `/api/admin/v1/Add-Zone`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_ZONE_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_ZONE_REJECTED", payload: error.response });
    }
  };
  

  export const getZoneListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_ZONELISTBYID_PENDING" });

      const response = await apiClient.get(
        `/api/admin/v1/Zone/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_ZONELISTBYID_FULFILLED",
        payload: response.data?.data,
        zoneCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_ZONELISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateZoneData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_ZONE_PENDING" });

    const response = await apiClient.post(
      `/api/admin/v1/Update-Zone/${id}`, // Endpoint URL with Zone ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_ZONE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_ZONE_REJECTED", payload: error.response });
  }
};


export const updateZoneStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_ZONE_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await apiClient.post(
      `/api/admin/v1/Update-Zone-Status/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_ZONE_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_ZONE_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteZoneData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_ZONE_PENDING" });

    const response = await apiClient.delete(
      `/api/admin/v1/Delete-Zone/${id}`, // Endpoint URL with Zone ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_ZONE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_ZONE_REJECTED", payload: error.response });
  }
};