import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const filterOptions = ["Equal", "Contains", "Greater", "Less"];

const FilterModal = ({ show, onHide, modalData, onApplyFilter, onRemoveFilter, onChange }) => {
  const { key, type, value } = modalData;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      centered
      dialogClassName="compact-modal"
    >
      <Modal.Header closeButton className="p-2">
        <Modal.Title style={{ fontSize: "1rem" }}>Filter {key}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <Form.Group>
          <Form.Label style={{ fontSize: "0.85rem" }}>Filter Type</Form.Label>
          <Form.Select
            value={type}
            onChange={(e) => onChange("type", e.target.value)}
            size="sm"
            style={{ fontSize: "0.85rem" }}
          >
            <option value="">Select Type</option>
            {filterOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label style={{ fontSize: "0.85rem" }}>Value</Form.Label>
          <Form.Control
            type="text"
            value={value}
            onChange={(e) => onChange("value", e.target.value)}
            size="sm"
            style={{ fontSize: "0.85rem" }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="p-1">
        <Button variant="outline-secondary" size="sm" onClick={() => onRemoveFilter(key)}>
          <FontAwesomeIcon icon={faTimes} /> Reset
        </Button>
        <Button variant="primary" size="sm" onClick={onApplyFilter}>
          <FontAwesomeIcon icon={faCheck} /> Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
